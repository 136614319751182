import React from 'react';

const DarkmodeIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 28C12.6667 28 9.83333 26.8333 7.5 24.5C5.16667 22.1667 4 19.3333 4 16C4 12.6667 5.16667 9.83333 7.5 7.5C9.83333 5.16667 12.6667 4 16 4C16.3111 4 16.6167 4.01111 16.9167 4.03333C17.2167 4.05556 17.5111 4.08889 17.8 4.13333C16.8889 4.77778 16.1611 5.61667 15.6167 6.65C15.0722 7.68333 14.8 8.8 14.8 10C14.8 12 15.5 13.7 16.9 15.1C18.3 16.5 20 17.2 22 17.2C23.2222 17.2 24.3444 16.9278 25.3667 16.3833C26.3889 15.8389 27.2222 15.1111 27.8667 14.2C27.9111 14.4889 27.9444 14.7833 27.9667 15.0833C27.9889 15.3833 28 15.6889 28 16C28 19.3333 26.8333 22.1667 24.5 24.5C22.1667 26.8333 19.3333 28 16 28Z"
        fill="currentColor"
      />
    </svg>
  );
};

DarkmodeIcon.displayName = 'DarkmodeIcon';

export default DarkmodeIcon;
