import { ChangeEvent } from 'react';
import { Control, useController } from 'react-hook-form';
import { Checkbox } from '@mantine/core';

import styles from './checkbox.module.css';

interface CheckboxFieldProps {
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  description?: string;
  disabled?: boolean;
  onChange?: (name: string, value: boolean) => void;
}

const CLASSES = {
  icon: styles.icon,
  body: styles.root
};

export const CheckboxField = (props: CheckboxFieldProps) => {
  const { name, label, control, description, disabled, onChange } = props;
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: false
  });
  const { ref, ...rest } = field;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(name, event.target.checked);

      return;
    }

    field.onChange(event.target.checked);
  };

  return (
    <CheckboxFieldBase
      {...rest}
      inputRef={ref}
      label={label}
      checked={field.value}
      description={description}
      error={fieldState.error ? fieldState.error.message : null}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CheckboxFieldBase(props: any) {
  const { inputRef, ...rest } = props;
  return <Checkbox {...rest} ref={inputRef} classNames={CLASSES} color="yellow.6" size="md" />;
}
