import dayjs from 'dayjs';

import { useGetAccount, useGetCurrentPlan, useGetSubscription } from '@/hooks';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import AccountLayout from '@/layouts/AccountLayout';
import { RichAvatar, Typo, SubscriptionLabel, Loader } from '@/components';
import { Subscription } from '@/models/Subscription';
import { Plan } from '@/models/Plan';

interface CurrentSubscriptionProps {
  isError: boolean;
  currentSubscription?: Subscription;
  currentPlan?: Plan;
}

function CurrentSubscriptionBlock({
  isError,
  currentSubscription,
  currentPlan
}: CurrentSubscriptionProps) {
  if (isError) {
    return null;
  }

  if (currentSubscription) {
    return (
      <div className="flex flex-col basis-1/2 gap-4 pt-4 justify-between">
        <div>
          <Typo.h6 className="text-hookybase-300">conversion time</Typo.h6>
          <div className="flex items-baseline mt-1">
            {currentSubscription?.remaining_credits === -1 ? (
              <Typo.h2 className="normal-case text-hookybase-400 dark:text-white text-4xl">
                Infinity
              </Typo.h2>
            ) : (
              <>
                <Typo.h2 className="normal-case text-hookybase-400 dark:text-white text-4xl">{`${currentSubscription?.remaining_credits}`}</Typo.h2>
                &nbsp;
                <Typo.h6 className="text-hookybase-400 dark:text-white">secs remaining</Typo.h6>
              </>
            )}
          </div>
        </div>
        {currentPlan ? (
          <p className="text-hookybase-300">{`Your next bill is $${currentPlan.amount} on ${dayjs(
            currentSubscription.end_at
          ).format(DEFAULT_DATE_FORMAT)}`}</p>
        ) : null}
      </div>
    );
  }

  return <Loader className="mt-0 px-0 py-0 basis-1/2" />;
}

const Account = () => {
  const { data: account } = useGetAccount();
  const { data: currentSubscription, isError } = useGetSubscription();
  const currentPlan = useGetCurrentPlan();
  const classname = 'flex gap-4 justify-between';

  return (
    <AccountLayout
      title="Account"
      subtitle="Manage your account information, settings and subscription"
      contentWrapperClassName={classname}
    >
      <div className="flex flex-wrap-reverse flex-1 gap-8 justify-between">
        <div className="flex flex-1 flex-col justify-between">
          <div className="flex flex-col basis-1/2 pb-6 border-b border-b-hookybase-400 gap-4 justify-between">
            {currentPlan ? <SubscriptionLabel label={currentPlan.name} /> : <div />}
            {account ? (
              <Typo.h2 className="normal-case text-hookybase-400 dark:text-white text-4xl">{`${account.first_name} ${account.last_name}`}</Typo.h2>
            ) : (
              <Loader className="mt-0 px-0 py-0" />
            )}
          </div>

          <CurrentSubscriptionBlock
            isError={isError}
            currentSubscription={currentSubscription}
            currentPlan={currentPlan}
          />
        </div>

        <div className="flex flex-col gap-6 justify-between">
          <RichAvatar avatarClassName="w-56 pt-[100%]" />
        </div>
      </div>
    </AccountLayout>
  );
};
export default Account;
