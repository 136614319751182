import { useEffect } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { ROUTES } from '@/routes/routes';
import { Footer, Icon, Nav, Typo, UserBar } from '@/components';

import styles from './site-terms-layout.module.css';

const NAV_LINKS = [
  {
    title: 'Privacy policy',
    link: ROUTES.PRIVACY_POLICY
  },
  {
    title: 'Terms of use',
    link: ROUTES.TERMS_OF_USE
  },
  {
    title: 'Paid subscription terms',
    link: ROUTES.SUBSCRIPTIONS_TERMS
  },
  {
    title: 'Cookie policy',
    link: ROUTES.COOKIE_POLICY
  }
];

export const SiteTermsLayout = () => {
  const theme = useMantineTheme();
  const md = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);
  const location = useLocation();
  const navigate = useNavigate();

  const isRootTermsRoute = location.pathname === ROUTES.SITE_TERMS;

  useEffect(() => {
    if (md && isRootTermsRoute) {
      navigate(ROUTES.PRIVACY_POLICY);
    }
  }, [md, isRootTermsRoute, navigate]);

  const navigateToTerms = () => navigate(ROUTES.SITE_TERMS);
  return (
    <>
      <main className="flex flex-col flex-1 pl-0 md:pl-20 py-4 md:py-6 relative">
        <UserBar isBackArrowShown={!isRootTermsRoute} handleBackClick={navigateToTerms} />
        <Nav />
        <section className="grid grid-cols-1 lg:grid-cols-6 relative">
          <aside
            className={twMerge(
              'block md:hidden lg:block col-start-1 col-end-3 lg:row-start-1 ml-16 mr-14',
              !md && isRootTermsRoute ? 'block' : '',
              !md && !isRootTermsRoute ? 'hidden' : ''
            )}
          >
            <section className="mb-9">
              <Typo.h3 className="mb-2 font-bold">SITE TERMS</Typo.h3>

              <ul>
                {NAV_LINKS.map((navLink) => (
                  <li key={navLink.title} className={styles.asideItem}>
                    <NavLink
                      to={navLink.link}
                      className={({ isActive }) => (isActive ? styles.active : '')}
                    >
                      <span>{navLink.title}</span>

                      <Icon icon="ChevronRight" size="3xl" />
                    </NavLink>
                  </li>
                ))}
              </ul>
            </section>
          </aside>

          <section
            className={twMerge(
              'relative row-start-1 lg:col-start-3 lg:col-end-7 ml-16 lg:ml-14 mr-16 overflow-auto',
              !md && isRootTermsRoute ? 'hidden' : '',
              !md && !isRootTermsRoute ? 'block' : ''
            )}
          >
            <div>
              <Outlet />
            </div>
          </section>
        </section>
      </main>

      <Footer />
    </>
  );
};
