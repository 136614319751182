import React from 'react';

const SkipBackIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.3335 24V8H10.0002V24H7.3335ZM24.6668 24L12.6668 16L24.6668 8V24Z"
        fill="currentColor"
      />
    </svg>
  );
};

SkipBackIcon.displayName = 'SkipBackIcon';

export default SkipBackIcon;
