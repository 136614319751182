import React from 'react';
import { Avatar } from '@mantine/core';

import { Icon, PlayButton } from '@/components';
import defaultSongImage from '@/assets/default-song-image.png';

import styles from './play-song-cell.module.css';

export interface PlaySongCellProps {
  data: {
    image_url: string;
    title: string;
    subtitle: string;
    master_url: string;
    id: string;
  };
  isLoading?: boolean;
}

export const PlaySongCell: React.FC<PlaySongCellProps> = (props) => {
  const { data, isLoading = false } = props;

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Icon icon="Spinner" className="animate-spin w-11 h-11" />
      ) : (
        <>
          <PlayButton
            song={data}
            classes={styles.playButton}
            data-cell-hover={data.master_url ? 'show' : 'disabled'}
          />

          <Avatar
            src={data.image_url || defaultSongImage}
            alt={data.title}
            size={44}
            data-cell-hover={data.master_url ? 'hide' : 'disabled'}
            className={styles.avatar}
            radius="sm"
          />
        </>
      )}

      <div>
        <div className={`text-black dark:text-white ${styles.nameSong}`}>{data.title}</div>
        <div
          className="text-hookybase-200 dark:text-hookybase-300 text-sm"
          data-cell-hover="text-white"
        >
          {data.subtitle}
        </div>
      </div>
    </div>
  );
};
