import { ChangeEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Navigate } from 'react-router-dom';

import {
  ButtonLib,
  CheckboxField,
  CheckboxFieldBase,
  Icon,
  ModalConfirm,
  OneButtonProps,
  SelectField,
  SliderField
} from '@/components';
import AccountLayout from '@/layouts/AccountLayout';
import { useGetArtistSettings, usePutArtistSettings, useGetAccount } from '@/hooks';
import { setServerFormError } from '@/utils/serverTestValidation';
import { ArtistSettingsProps } from '@/models/Account';
import { ROUTES } from '@/routes/routes.tsx';
import { useModalStore } from '@/store';

import styles from '../account.module.css';

type FormValues = ArtistSettingsProps;

const SCHEMA = Yup.object().shape({
  royalty_percent: Yup.number().required('Split is required'),
  auto_approve: Yup.boolean().required('Approval settings is required'),
  approval_days: Yup.number().when('auto_approve', ([auto_approve], schema) => {
    return auto_approve ? schema.required('Period is required') : schema.optional();
  })
});

const MODAL_CONFIRM_AUTO_APPROVE = 'auto-approve-warning';
const MODAL_CONFIRM_MANUAL_APPROVE_CHANGE_TIME = 'manual-approve-warning-time-change';
const BUTTON_CONFIRM_PROPS: OneButtonProps = {
  text: 'Ok',
  variant: 'filled',
  background: 'primary',
  size: 'lg'
};

const PERIOD_OPTIONS = [
  {
    value: '-1',
    label: 'Immediately'
  },
  {
    value: '1',
    label: '1 day'
  },
  {
    value: '3',
    label: '3 day'
  },
  {
    value: '5',
    label: '5 day'
  },
  {
    value: '7',
    label: '7 day'
  },
  {
    value: '14',
    label: '14 day'
  }
];

export const ArtistApproval = () => {
  const { mutate: updateSettings, isLoading, error } = usePutArtistSettings();
  const { data: settings, isSuccess, isLoading: isLoadingSettings } = useGetArtistSettings();
  const { data: account } = useGetAccount();
  const { handleSubmit, control, reset, watch, setValue, formState, setError } =
    useForm<FormValues>({
      resolver: yupResolver(SCHEMA)
    });

  const { openModal, closeModal } = useModalStore();

  useEffect(() => {
    if (isSuccess) {
      reset({
        ...settings,
        approval_days: settings.approval_days?.toString()
      } as ArtistSettingsProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      setServerFormError(error, setError);
    }
  }, [error, setError]);

  const isAutoApproval = watch('auto_approve');

  const handleChangeManualApprove = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;

    if (!value) {
      openModal({ name: MODAL_CONFIRM_AUTO_APPROVE });
    }

    setValue('auto_approve', !value, { shouldDirty: true });
    setValue('approval_days', settings?.approval_days);
  };

  const handleChangeAutoApprove = (name: string, value: boolean) => {
    if (value && !settings?.auto_approve) {
      openModal({ name: MODAL_CONFIRM_AUTO_APPROVE });

      setValue('approval_days', settings?.approval_days);
    }

    setValue(name as keyof FormValues, value);
  };

  const handleChangeTime = (name: string, value: string | null) => {
    const selectedValue = value ? +value : 1;

    if ((settings?.approval_days || 1) > selectedValue) {
      openModal({ name: MODAL_CONFIRM_MANUAL_APPROVE_CHANGE_TIME });
    }

    setValue(name as keyof FormValues, selectedValue, { shouldDirty: true });
  };

  const handleSave = handleSubmit(({ royalty_percent, auto_approve, approval_days }) => {
    updateSettings({
      auto_approve,
      royalty_percent,
      approval_days: approval_days ? +approval_days : undefined
    });
  });

  const handleCancel = () => {
    reset();
  };

  const handleCloseModal = () => {
    closeModal();
  };

  return account?.artist ? (
    <AccountLayout
      title="ARTIST SETTINGS"
      subtitle="Manage your royalty split and approval settings"
      contentWrapperClassName="min-h-[550px] flex flex-col"
    >
      {isLoadingSettings ? (
        <Icon icon="Spinner" className="animate-spin w-16 h-16" />
      ) : (
        <>
          <h2 className={styles.titleSmall}>License Split</h2>

          <p className={styles.subtitle}>
            Please select the percentage split you would like to take as commission on your license
            agreement when creators use your voice.
          </p>

          <form onSubmit={handleSave}>
            <div className={styles.slider}>
              <SliderField
                valueUnits="%"
                name="royalty_percent"
                control={control}
                thumbAlwaysShown
                description="*required"
                maxValue={90}
                maxLength={2}
              />
            </div>

            <h2 className={styles.titleSmall}>Approval settings</h2>

            <p className={styles.subtitle}>
              Choose how you would like to approve projects using your voice, don’t worry, even if
              you approve a song you have the ability to take them down.
            </p>

            <div>
              <div className={styles.checkboxes}>
                <CheckboxFieldBase
                  label="Manually approve projects"
                  onChange={handleChangeManualApprove}
                  checked={!isAutoApproval}
                />

                <CheckboxField
                  name="auto_approve"
                  control={control}
                  label="Use auto-approval"
                  onChange={handleChangeAutoApprove}
                />
              </div>

              <p className="mb-4 text-xs text-hookybase-200">*required</p>
            </div>

            <div className={isAutoApproval ? '' : 'hidden'}>
              <SelectField
                name="approval_days"
                placeholder="Select time period"
                control={control}
                options={PERIOD_OPTIONS}
                onChange={handleChangeTime}
              />
            </div>

            <div className={`${styles.formActions} ${styles.formFooter}`}>
              <ButtonLib background="secondary" variant="outline" onClick={handleCancel}>
                Cancel
              </ButtonLib>

              <ButtonLib type="submit" loading={isLoading} disabled={!formState.isDirty}>
                Save
              </ButtonLib>
            </div>
          </form>

          <ModalConfirm
            name={MODAL_CONFIRM_AUTO_APPROVE}
            title="Warning"
            subtitle="After saving the settings, all current songs waiting for approval will be automatically approved."
            onConfirm={handleCloseModal}
            oneButton={BUTTON_CONFIRM_PROPS}
          />

          <ModalConfirm
            name={MODAL_CONFIRM_MANUAL_APPROVE_CHANGE_TIME}
            title="Warning"
            subtitle="By changing the value to less than it was, all songs whose time for approval has passed will be automatically approved."
            onConfirm={handleCloseModal}
            oneButton={BUTTON_CONFIRM_PROPS}
          />
        </>
      )}
    </AccountLayout>
  ) : (
    <Navigate to={ROUTES.DASHBOARD} />
  );
};
