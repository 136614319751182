import React from 'react';

const MenuDotsIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0002 26.6667C15.2668 26.6667 14.6391 26.4056 14.1168 25.8834C13.5946 25.3612 13.3335 24.7334 13.3335 24C13.3335 23.2667 13.5946 22.6389 14.1168 22.1167C14.6391 21.5945 15.2668 21.3334 16.0002 21.3334C16.7335 21.3334 17.3613 21.5945 17.8835 22.1167C18.4057 22.6389 18.6668 23.2667 18.6668 24C18.6668 24.7334 18.4057 25.3612 17.8835 25.8834C17.3613 26.4056 16.7335 26.6667 16.0002 26.6667ZM16.0002 18.6667C15.2668 18.6667 14.6391 18.4056 14.1168 17.8834C13.5946 17.3612 13.3335 16.7334 13.3335 16C13.3335 15.2667 13.5946 14.6389 14.1168 14.1167C14.6391 13.5945 15.2668 13.3334 16.0002 13.3334C16.7335 13.3334 17.3613 13.5945 17.8835 14.1167C18.4057 14.6389 18.6668 15.2667 18.6668 16C18.6668 16.7334 18.4057 17.3612 17.8835 17.8834C17.3613 18.4056 16.7335 18.6667 16.0002 18.6667ZM16.0002 10.6667C15.2668 10.6667 14.6391 10.4056 14.1168 9.88337C13.5946 9.36115 13.3335 8.73337 13.3335 8.00004C13.3335 7.26671 13.5946 6.63893 14.1168 6.11671C14.6391 5.59449 15.2668 5.33337 16.0002 5.33337C16.7335 5.33337 17.3613 5.59449 17.8835 6.11671C18.4057 6.63893 18.6668 7.26671 18.6668 8.00004C18.6668 8.73337 18.4057 9.36115 17.8835 9.88337C17.3613 10.4056 16.7335 10.6667 16.0002 10.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

MenuDotsIcon.displayName = 'MenuDotsIcon';

export default MenuDotsIcon;
