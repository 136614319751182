import React from 'react';

const NotificationsIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.3335 25.3333V22.6666H8.00016V13.3333C8.00016 11.4888 8.55572 9.84996 9.66683 8.41663C10.7779 6.98329 12.2224 6.0444 14.0002 5.59996V4.66663C14.0002 4.11107 14.1946 3.63885 14.5835 3.24996C14.9724 2.86107 15.4446 2.66663 16.0002 2.66663C16.5557 2.66663 17.0279 2.86107 17.4168 3.24996C17.8057 3.63885 18.0002 4.11107 18.0002 4.66663V5.59996C19.7779 6.0444 21.2224 6.98329 22.3335 8.41663C23.4446 9.84996 24.0002 11.4888 24.0002 13.3333V22.6666H26.6668V25.3333H5.3335ZM16.0002 29.3333C15.2668 29.3333 14.6391 29.0722 14.1168 28.55C13.5946 28.0277 13.3335 27.4 13.3335 26.6666H18.6668C18.6668 27.4 18.4057 28.0277 17.8835 28.55C17.3613 29.0722 16.7335 29.3333 16.0002 29.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

NotificationsIcon.displayName = 'NotificationsIcon';

export default NotificationsIcon;
