import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useApi } from '@/hooks';
import { ArtistSettingsProps } from '@/models/Account';

export const useGetArtistSettings = (): UseQueryResult<ArtistSettingsProps, AxiosError> => {
  const api = useApi({});

  return useQuery<ArtistSettingsProps, AxiosError>({
    queryKey: ['account-artist-settings'],
    queryFn: async (): Promise<ArtistSettingsProps> => {
      const { data } = await api.get<ArtistSettingsProps>('/v1/account/approvals');

      return data;
    }
  });
};
