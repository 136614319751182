import { MouseEvent } from 'react';

import { ItemMenu, MenuOptionProps } from '@/components';
import { ISong } from '@/models/Song.ts';
import { useModalStore } from '@/store';
import { AGREED_TERMS_UPLOAD_TRACK_MODAL_NAME, DELETE_SONG_MODAL_NAME } from '@/constants/song';

interface ItemMenuCellProps {
  data: ISong;
}

export const ItemMenuCell = (props: ItemMenuCellProps) => {
  const { data } = props;

  const isEligibleForReuploadMaster =
    data.status === 'awaiting_approval' ||
    data.status === 'change_artist' ||
    data.status === 'release_song';

  const { openModal } = useModalStore();

  const buttonProps = {
    'data-cell-hover': 'show',
    onClick: (event: MouseEvent<HTMLButtonElement>) => event.stopPropagation()
  };

  const handleConfirmDelete = (event: unknown) => {
    (event as MouseEvent<HTMLButtonElement>).stopPropagation();

    openModal({ name: DELETE_SONG_MODAL_NAME, songId: data.id });
  };

  const handleReuploadMaster = () => {
    openModal({
      name: AGREED_TERMS_UPLOAD_TRACK_MODAL_NAME,
      voiceId: data.id,
      title: data.name,
      artistName: data.display_artist,
      split: `${data.voices[0].royalty_percent}`
    });
  };

  const options: MenuOptionProps[] = [
    {
      label: 'Delete',
      onClick: (event: unknown) => handleConfirmDelete(event)
    }
  ];

  if (data.master_url && isEligibleForReuploadMaster && data.requires_license) {
    options.push({
      label: 'Reupload Master',
      onClick: () => handleReuploadMaster()
    });
  }

  return <ItemMenu options={options} buttonProps={buttonProps} />;
};
