import React from 'react';

const XWhiteIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Artwork" clipPath="url(#clip0_3575_20864)">
        <path
          id="Vector"
          d="M13.0923 9.74087L21.2859 0H19.344L12.2341 8.45918L6.55239 0H0L8.58827 12.7849L0 23H1.94191L9.45273 14.0666L15.4539 23H22.0063L13.0985 9.74087H13.0923ZM10.4362 12.9003L9.56549 11.625L2.63724 1.49317H5.61902L11.2067 9.67038L12.0774 10.9457L19.344 21.5773H16.3622L10.4362 12.9067V12.9003Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3575_20864">
          <rect width="22" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

XWhiteIcon.displayName = 'XWhiteIcon';

export default XWhiteIcon;
