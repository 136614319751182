import React from 'react';

const NotificationsOnIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.33301 25.3334V22.6667H7.99967V13.3334C7.99967 11.4889 8.55523 9.85002 9.66634 8.41669C10.7775 6.98335 12.2219 6.04446 13.9997 5.60002V4.66669C13.9997 4.11113 14.1941 3.63891 14.583 3.25002C14.9719 2.86113 15.4441 2.66669 15.9997 2.66669C16.5552 2.66669 17.0275 2.86113 17.4163 3.25002C17.8052 3.63891 17.9997 4.11113 17.9997 4.66669V5.60002C19.7775 6.04446 21.2219 6.98335 22.333 8.41669C23.4441 9.85002 23.9997 11.4889 23.9997 13.3334V22.6667H26.6663V25.3334H5.33301ZM15.9997 29.3334C15.2663 29.3334 14.6386 29.0722 14.1163 28.55C13.5941 28.0278 13.333 27.4 13.333 26.6667H18.6663C18.6663 27.4 18.4052 28.0278 17.883 28.55C17.3608 29.0722 16.733 29.3334 15.9997 29.3334Z"
        fill="currentColor"
      />
      <circle cx="28" cy="4" r="4" fill="#ECE81A" />
    </svg>
  );
};

NotificationsOnIcon.displayName = 'NotificationsIcon';

export default NotificationsOnIcon;
