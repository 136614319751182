import { Typo } from '@/components';

import styles from '../site-terms.module.css';
import { useEffect } from 'react';

export const PrivacyPolicy = () => {
  useEffect(() => {
    const section = document.querySelector('#privacy-policy-section');

    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <div>
      <div id="privacy-policy-section" className={styles.titleBox}>
        <Typo.h1 className="mb-2">Privacy Policy</Typo.h1>
      </div>

      <div className={`${styles.box} ${styles.content}`}>
        <p>
          Hooky AI, Inc. (“<b>Company</b>,” “<b>us</b>,” “<b>we</b>,” or “<b>our</b>”) respects the
          privacy of individuals who provide us with personal information by visiting our website,
          including the website{' '}
          <a href="https://www.hooky.co/" target="_blank">
            https://www.hooky.co/
          </a>
          , the “<b>Site</b>”), or using the Hooky Platform and Services (together, the “
          <b>Services</b>”). This Privacy Policy explains how we collect, use, share your personal
          information, the rights and choices you may have with respect to your personal
          information, and how to assert those rights.
        </p>

        <p>
          We may make changes to this Privacy Policy from time to time. Please review the “Last
          Updated” legend at the top of this page to determine when this Privacy Policy was last
          updated. Any changes to this Privacy Policy will become effective on the “Last Updated”
          date indicated above. If we make material changes to this Privacy Policy, then we will
          notify users by posting a notice on our website and/or via email prior to such use or by
          other means as required by law.
        </p>

        <p>
          This Privacy Policy does not apply to the personal information we collect from employees,
          contractors, job applicants, owners, directors or officers of the Company.
        </p>

        <p>
          <b>
            By accessing, visiting or using our Services, you acknowledge this Privacy Policy. If
            you are not comfortable with any part of this Policy, please immediately discontinue
            access or use of our Services.
          </b>
        </p>

        <ul>
          <li>
            <a href="#personal-info">Personal Information We Collect</a>
          </li>

          <li>
            <a href="#how-we-use-your-personal-information">How We Use Your Personal Information</a>
          </li>

          <li>
            <a href="#how-we-use-disclose-personal-information">
              How We Disclose Your Personal Information
            </a>
          </li>

          <li>
            <a href="#cookie-and-other">Cookies and Other Tracking Technologies</a>
          </li>

          <li>
            <a href="#your-privacy-choices">Your Privacy Choices</a>
          </li>

          <li>
            <a href="#children">Children</a>
          </li>

          <li>
            <a href="#retention">Retention</a>
          </li>

          <li>
            <a href="#security-your-personal-information">Security of Your Personal Information</a>
          </li>

          <li>
            <a href="#international-transfer">International Transfer of Personal Information </a>
          </li>

          <li>
            <a href="#additional-notice-canada">Additional Notice to Individuals in Canada</a>
          </li>

          <li>
            <a href="#additional-notice-other">
              Additional Notice to Individuals in the European Economic Area, the United Kingdom,
              and Switzerland
            </a>
          </li>

          <li>
            <a href="#contact-us">How to Contact Us</a>
          </li>
        </ul>

        <div id="personal-info">
          <h2>1. PERSONAL INFORMATION WE COLLECT</h2>

          <p>
            We collect certain personal information when you use our Services. Personal information
            is typically data that identifies an individual or relates to an identifiable individual
            but depends on the local law where you reside. The personal information that we collect
            depends on your interactions with us, and the choices you make.
          </p>

          <p>
            <b>Information You Voluntarily Provide</b>. The types of personal information that we
            may collect from you include:
          </p>
          <ul className={styles.ulCircle}>
            <li>
              <b>Artist Contact Registration</b>. We may collect information relating to the
              creation of an account for Artist submissions to Hooky, such as your first and last
              name and email address.
            </li>

            <li>
              <b>Form Submissions, Newsletter Signups, and Early Access</b>. We may collect your
              contact information, including email address, if you request to sign-up for more
              information or to join a mailing list.
            </li>

            <li>
              <b>Customer Service</b>. We may collect records and copies of your correspondence if
              you contact us by telephone or in writing, including for customer service.
            </li>
          </ul>

          <p>
            Information We Automatically Collect. To the extent permitted under the applicable law,
            we may automatically collect certain type of personal information about you when you use
            the Site or interact with the Services.
          </p>
        </div>

        <div id="how-we-use-your-personal-information">
          <h2>2. HOW WE USE YOUR PERSONAL INFORMATION</h2>

          <p>
            We may use your personal information for the following business and commercial purposes,
            unless restricted by applicable law:
          </p>

          <ul>
            <li>
              <p>
                <u>To Provide Our Services.</u>
              </p>

              <p>
                We use personal information we gather through the Services to provide you with our
                Services that you request, such as subscribing for early access to Hooky and artist
                submissions and using the Hooky Platform and Services to create New Recordings.
              </p>
            </li>

            <li>
              <p>
                <u>To Provide You with Service-Related Communications.</u>
              </p>

              <p>
                We may use your personal information to communicate with you. We may send you
                administrative information for early access after subscribing to keep you updated
                about the Services. Such communications may include information about privacy policy
                and terms updates, security updates, tips or other relevant transaction-related
                information. Service-related communications are not promotional in nature. You are
                not able to unsubscribe from such communications, otherwise you may miss important
                developments relating to your account or the Services.{' '}
              </p>
            </li>

            <li>
              <p>
                <u>To Provide Marketing Communications.</u>
              </p>

              <p>
                If you have voluntarily provided your email address, phone number or other
                electronic addresses to us when subscribing to our marketing and early access
                communications, we may email you about products, services, and promotions that we
                believe may be of interest to you. When you no longer wish to receive these
                marketing messages from us, you can opt out at any time by following the
                instructions contained in each email you receive from us or contacting us via the
                methods specified in the “How to Contact Us” section below.
              </p>
            </li>

            <li>
              <p>
                <u>To Research and Develop Our Services.</u>
              </p>

              <p>
                We may process your personal information and derive analytical and statistical data
                to better understand the way you use and interact with our Services. For instance,
                analyzing where, on which types of devices and how our Site is used, how many
                visitors we receive, and where they click on the Site may help us improve our
                existing Services and to build new Services. Please see our
                <em>Cookies and Other Similar Tracking Technologies </em>section for more
                information.
              </p>
            </li>

            <li>
              <p>
                <u>To Personalize Your Experience. </u>
              </p>

              <p>
                We may process your information to personalize your experience. By personalization,
                we enable you to interact with our Services more easily across platforms and
                devices.
              </p>
            </li>

            <li>
              <p>
                <u>To Facilitate Corporate Acquisitions, Mergers and Transactions.</u>
              </p>

              <p>
                We may process any information regarding your account and your use of our Services
                as is necessary in the context of corporate acquisitions, mergers or other corporate
                transactions.
              </p>
            </li>

            <li>
              <p>
                <u>To Enforce Compliance with Our Terms, Agreements and Policies.</u>
              </p>

              <p>
                We may process your personal information when actively monitoring, investigating,
                preventing and mitigating any alleged or actual prohibited, illicit or illegal
                activities on our Services. We may also process your personal information to
                investigate, prevent or mitigate violations of our internal terms, agreements or
                policies; and enforce our agreements with third parties.
              </p>
            </li>

            <li>
              <p>
                <u>To Ensure the Security of the Services.</u>
              </p>

              <p>
                We may process your personal information to combat spam, malware, malicious
                activities or security risks; improve and enforce our security measures; and to
                monitor and verify your identity so that unauthorized users do not gain access to
                your information.
              </p>
            </li>

            <li>
              <p>
                <u>To Maintain Legal and Regulatory Compliance.</u>
              </p>

              <p>
                Our Services are subject to certain laws and regulations which may require us to
                process your personal information. For example, we process your personal information
                to fulfill our business obligations or as necessary to manage risk as required under
                applicable law, or to respond to requests by judicial process or governmental
                agencies.
              </p>
            </li>

            <li>
              <p>
                <u>With Your Consent.</u>
              </p>

              <p>
                For any other purpose disclosed to you prior to you providing us your personal
                information or which is reasonably necessary to provide the services or other
                related services requested, with your permission or upon your direction.
              </p>
            </li>

            <li>
              <p>
                <u>To Create Augmented Recordings.</u>
              </p>

              <p>
                We process Creator Recording information and Voice Artist information together with
                metadata or other information you may provide to create AI-enhanced Augmented
                Recordings per your request for us to do so in using the Hooky Platform and
                Services. In doing so, we may also use the personal information you provide to
                create Augmented Recordings and related materials.
              </p>
            </li>
          </ul>
        </div>

        <div id="how-we-use-disclose-personal-information">
          <h2>HOW WE DISCLOSE YOUR PERSONAL INFORMATION</h2>

          <p>We may disclose your personal information as described below:</p>

          <ul>
            <li>
              <p>
                <u>Within Our Corporate Organization.</u>
              </p>

              <p>
                We may share some or all of your personal information with our subsidiaries and
                corporate affiliates, joint ventures, or other companies under common control with
                us. If you would like us to stop providing your information to our affiliates for
                their own marketing purposes, you may opt-out by emailing us at{' '}
                <b>privacy@hooky.co</b>.
              </p>
            </li>

            <li>
              <p>
                <u>With Our Service Providers and Business Partners.</u>
              </p>

              <p>
                We may disclose your personal information to our contractors, affiliates,
                distributors and suppliers (together, “<b>Service Providers</b>”) who provide
                certain services to us or on our behalf, such as operating and supporting the
                Services, analyzing data, or performing marketing or consulting services. These
                Service Providers will only have access to the information needed to perform these
                limited functions on our behalf.
              </p>
            </li>

            <li>
              <p>
                <u>
                  Response to Subpoenas or Court Orders or to Protect Rights and to Comply with Our
                  Policies.
                </u>
              </p>

              <p>
                We may disclose personal information to government authorities or third parties if:
                (i) required to do so by law, or in response to law enforcement requests and legal
                processes, such as a subpoena or court order; (ii) comply with requests from
                auditors, examiners or other regulators; (iii) we believe in our sole discretion
                that disclosure is reasonably necessary to protect against fraud, to protect the
                property or other rights of us or other users, third parties or the public at large;
                or (iv) we believe that you have abused the Site or the Services by using either of
                them to attack other systems or to gain unauthorized access to any other system, to
                engage in spamming or otherwise to violate applicable laws. You should be aware
                that, following disclosure to any third party, your information may be accessible by
                others to the extent permitted or required by applicable law.
              </p>
            </li>

            <li>
              <p>
                <u>During Business Transfers; Bankruptcy.</u>
              </p>

              <p>
                In the event of a merger, acquisition, bankruptcy or other sale of all or a portion
                of our assets, any personal information owned or controlled by us may be one of the
                assets transferred to third parties. We reserve the right, as part of this type of
                transaction, to transfer or assign personal information and other information we
                have collected from users of the Services to buyers, service providers, advisors,
                potential transactional partners or other third parties in connection with the
                advisors, potential transactional partners or other third parties of a corporate
                transaction. Other than to the extent ordered by a bankruptcy or other court, the
                use and disclosure of all transferred user information will be subject to this
                Privacy Policy. However, any information you submit or that is collected after this
                type of transfer may be subject to a new privacy policy adopted by the successor
                entity.
              </p>
            </li>

            <li>
              <p>
                <u>With Marketing Partners.</u>
              </p>

              <p>
                If you affirmatively opt-in to receive marketing communications from third parties,
                we may, from time to time, share personal information you provide to us with
                selected third-party marketing partners that we believe offer products or services
                that may be of interest to you. If you would like us to stop providing your
                information to our third-party marketing partners, you may opt-out by emailing us at
                <b>privacy@hooky.co</b>. To learn more about your choices and rights, please review
                the “<u>Your Choices</u>” section below.
              </p>
            </li>

            <li>
              <p>
                <u>With Your Consent.</u>
              </p>

              <p>
                We may share your personal information with other companies if you give us
                permission or direct us to share your information.
              </p>
            </li>
          </ul>
        </div>

        <div id="cookie-and-other">
          <h2>COOKIES AND OTHER TRACKING TECHNOLOGIES</h2>

          <p>
            We, and our service providers, may automatically collect information from you when you
            use the Site using “cookies” and other similar technologies, such as web beacons
            (collectively, “<b>Tracking Technologies</b>”), to automatically collect information
            about you when you use the Site and to personalize your experience with our Services. We
            and third-party businesses on our behalf may use Tracking Technologies to help us
            customize your experience and better manage content on our Sites.
          </p>

          <p>
            Cookies are small amounts of data that are stored within your computer’s internet
            browser and that are accessed and recorded by the websites that you visit so that they
            can recognize the same browser navigating online at a later time. Information that may
            be collected by cookies when you use the Site may include, without limitation: the pages
            you visit within the Site; the date and time of your visit to the Site; the amount of
            time you spend using the Site; the IP address used to connect your computer to the
            internet; and/or your computer and connection information such as your browser type and
            version, operating system and platform.
          </p>

          <p>
            Generally, we use first-party and third-party cookies for the following purposes: to
            make our Services function properly; to provide a secure browsing experience during your
            use of our Services; to collect passive information about your use of our Services; to
            measure how you interact with our marketing campaigns; to help us improve our Services;
            and to remember your preferences for your convenience.
          </p>

          <p>
            We may place cookies from third-party service providers who may use information about
            your visits to other websites to target advertisements for products and services
            available from us. We do not control the types of information collected and stored by
            these third-party cookies. You should check the third-party's website for more
            information on how they use cookies.
          </p>

          <p>
            Your browser may provide you with the option to refuse some or all browser cookies. You
            may also be able to remove cookies from your browser. You can exercise your preferences
            in relation to cookies served on our Site by taking the steps outlined below.
          </p>

          <p>
            <u>First-Party Cookies</u>. You can use the browser with which you are viewing this Site
            to enable, disable or delete cookies. To do this, follow the instructions provided by
            your browser (usually located within the “Help”, “Tools” or “Edit” settings). Please
            note, if you set your browser to disable cookies, you may not be able to access secure
            areas of the Site. Also, if you disable cookies other parts of the Site may not work
            properly. You can find more information about how to change your browser cookie settings
            at{' '}
            <a href="https://www.allaboutcookies.org" target="_blank">
              https://www.allaboutcookies.org
            </a>
            .
          </p>

          <p>
            <u>Third-Party Cookies</u>. Many advertising companies that collect information for
            interest-based advertising are members of the Digital Advertising Alliance (DAA) or the
            Network Advertising Initiative (NAI), both of which maintain websites where people can
            opt out of interest-based advertising from their members. To opt-out of website
            interest-based advertising provided by each organization’s respective participating
            companies, visit the DAA’s opt-out portal available at{' '}
            <a href="http://optout.aboutads.info/" target="_blank">
              http://optout.aboutads.info/
            </a>
            , or visit the NAI’s opt-out portal available at{' '}
            <a href="http://optout.networkadvertising.org/?c=1" target="_blank">
              http://optout.networkadvertising.org/?c=1
            </a>
            .
          </p>

          <p>
            <u>Analytics</u>. We may use third-party service providers to monitor and analyze the
            use of our Site. Presently, we use Google Analytics. Google Analytics is a web analytics
            service offered by Google LLC (“<b>Google</b>”) that tracks and reports Site traffic.
            For more information on the privacy practices of Google, please visit the Google Privacy
            & Terms web page:{' '}
            <a href="https://policies.google.com/privacy?hl=en" target="_blank">
              https://policies.google.com/privacy?hl=en
            </a>
            . Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent
            their data from being collected and used by Google Analytics, available at:
            <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">
              https://tools.google.com/dlpage/gaoptout
            </a>
            .
          </p>

          <p>
            <u>Do Not Track.</u> Some internet browsers, such as Internet Explorer, Firefox, and
            Safari, include the ability to transmit “Do Not Track” or “DNT” signals. Since uniform
            standards for “DNT” signals have not been adopted, our Site does not currently process
            or respond to “DNT” signals.
          </p>
        </div>

        <div id="your-privacy-choices">
          <h2>YOUR PRIVACY CHOICES</h2>

          <p>
            We offer you certain choices about how we communicate with you and manage what personal
            information we collect from you.
          </p>

          <ul>
            <li>
              <p>
                <u>Marketing Communications</u>
              </p>

              <p>
                We offer you certain choices about how we communicate with you. To update your
                preferences, limit the communications you receive from us, please contact us as
                specified in the “<u>How to Contact Us</u>” section at the end of this Privacy
                Policy. Where provided by law, you may withdraw your consent previously provided to
                us, and we will apply your preferences going forward. You can also choose not to
                receive marketing communications from us by clicking on the unsubscribe link in our
                emailed marketing content.
              </p>
            </li>

            <li>
              <p>
                <u>Managing Cookies</u>
              </p>

              <p>
                You have the right to decide whether to accept cookies. If you do not want us to
                deploy cookies in your browser, you may exercise your preference by clicking “Your
                Privacy Rights” in the footer, by clicking here or by modifying your web browser
                setting to refuse some or all cookies or to notify you and ask for your permission
                when a website tries to set a cookie. If you want to learn how to modify your
                browser settings, please use the “Help,” “Tools” or “Edit” menu in your browser or
                review the instructions provided by the following browsers:{' '}
                <a
                  href="https://hooky.atlassian.net/wiki/spaces/HK/pages/188448826/Privacy+Policy#:~:text=the%20following%20browsers%3A-,Microsoft%20Edge,-%2C%20Google%20Chrome"
                  target="_blank"
                >
                  Microsoft Edge
                </a>
                ,{' '}
                <a href="https://support.google.com/chrome/answer/95647?hl=en-GB" target="_blank">
                  Google Chrome
                </a>
                ,{' '}
                <a
                  href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                  target="_blank"
                >
                  Mozilla Firefox
                </a>
                ,{' '}
                <a
                  href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac"
                  target="_blank"
                >
                  Safari Desktop
                </a>
                ,{' '}
                <a href="https://support.apple.com/en-us/HT201265" target="_blank">
                  Safari Mobile
                </a>
                ; and{' '}
                <a
                  href="https://support.google.com/nexus/answer/54068?visit_id=637284515772371005-3491757126&hl=en&rd="
                  target="_blank"
                >
                  Android
                </a>{' '}
                browser. If you block or delete technical and/or function cookies used by our Sites,
                the Sites may become impossible to browse, certain services or functions of the
                Sites may become unavailable or other malfunctions may occur. In this case, you may
                have to modify or manually enter some information or preferences every time you
                visit the Sites. Please see the Cookies and Other Tracking Technologies section
                above for more information about opting out of 3rd party cookies.Microsoft Edge,
                Google Chrome, Mozilla Firefox, Safari Desktop, Safari Mobile; and Android
              </p>
            </li>

            <li>
              <p>
                <u>Correction and Deletion</u>
              </p>

              <p>
                You may request us to rectify, correct or update any of your personal information
                held by us that is inaccurate. Under certain circumstances, you may have the right
                to request that we delete the personal information that we hold about you. This
                right is not absolute, and we may refuse your right to delete if it is reasonably
                necessary for us to provide a service requested by you; to perform a contract
                between us; to maintain functionality and ensure security of our systems; to enable
                solely expected internal uses of your personal information; to exercise a right
                provided by law; to comply with a legal obligation, or if there are compelling
                legitimate grounds for keeping your personal information. To make such a request,
                please contact us as noted in the “How to Contact Us” section below.
              </p>
            </li>
          </ul>
        </div>

        <div id="children">
          <h2>CHILDREN</h2>

          <p>
            Our Services are not intended for children. We do not knowingly collect or distribute
            personal information that we collect directly from children. If you are a parent or
            guardian and wish to review information collected from your child, or have that
            information modified or deleted, you may contact us via the method identified in the “
            <u>How to Contact Us</u>” section below. If we become aware that a child has provided us
            with personal information, we will terminate the user’s access to our Service and take
            steps to delete such information from our systems, unless we have a legal obligation to
            keep it.
          </p>
        </div>

        <div id="retention">
          <h2>RETENTION</h2>

          <p>
            We will retain your information for as long as your account is active, as needed to
            provide you Services or for other purposes stated in this Policy. We will cease to
            retain your personal information or remove the means by which the personal information
            can be associated with you as soon as it is reasonable to assume that such retention no
            longer serves the purpose for which the personal information was collected and is no
            longer necessary for legal or business purposes. If you wish to cancel your account or
            request that we no longer use your information to provide you services, we may still
            retain and use your information as necessary to comply with our legal obligations,
            resolve disputes, and enforce our agreements.
          </p>
        </div>

        <div id="security-your-personal-information">
          <h2>SECURITY OF YOUR PERSONAL INFORMATION</h2>

          <p>
            We implement security measures intended to protect against the loss, misuse and
            alteration of the personal information under our control. Please be aware that no data
            transmission over the internet can be guaranteed to be 100% secure. We cannot guarantee
            the security of our databases or the databases of the third parties with which we may
            share such information, nor can we guarantee that the information you supply will not be
            intercepted while being transmitted over the internet. Please remember that you play a
            valuable part in security as well. If you feel that the security of any account you
            might have with us has been compromised, you should contact us immediately as described
            in the “<u>How to Contact Us</u>” section.
          </p>
        </div>

        <div id="international-transfer">
          <h2>INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION</h2>

          <p>
            We are an AI-based service for artists and producers, based in the United States. Our
            business operates on a global scale. When you access or submit information to us, your
            personal information may be transferred to, processed, maintained, and used on
            computers, servers and systems located where the data protection laws may not be as
            protective as those in your jurisdiction. We will take appropriate contractual or other
            steps to protect the relevant personal information in accordance with applicable laws.
          </p>
        </div>

        <div id="additional-notice-canada">
          <h2>ADDITIONAL NOTICE TO INDIVIDUALS IN CANADA</h2>

          <p>
            This Section provides additional information to individuals located in Canada at the
            time their personal information is collected by Hooky. You may request details about our
            privacy practices, access or correct your personal information, or make a complaint by
            us by sending an email to privacy@hooky.co. If you are not satisfied with our response
            to your inquiry, you may contact the Office of the Privacy Commissioner of Canada:
            1-800-282-1376 (toll-free) or at priv.gc.ca.
          </p>
        </div>

        <div id="additional-notice-other">
          <h2>
            ADDITIONAL NOTICE TO INDIVIDUALS IN THE EUROPEAN ECONOMIC AREA, THE UNITED KINGDOM, AND
            SWITZERLAND
          </h2>

          <p>
            This section only applies to individuals using or accessing our Services while located
            in the European Economic Area (“<b>EEA</b>”), the United Kingdom, and Switzerland
            (collectively, the “<b>European Countries</b>”) at the time of data collection. Pursuant
            to the European Union’s General Data Protection Regulation, and the UK General Data
            Protection Regulation (collectively, the “<b>GDPR</b>”), for the sake of clarity,
            references to personal information in this Policy concern personal data in the sense of
            the GDPR.
          </p>

          <p>
            We may ask you to identify which country you are located in when you use or access some
            of the Services, or we may rely on your IP address to identify which country you are
            located in. If any terms in this section conflict with other terms contained in this
            Policy, the terms in this section shall apply to individuals in European Countries.
          </p>

          <p>
            You may direct questions or otherwise exercise your rights in respect of your personal
            data by sending an email to <b>privacy@hooky.co</b>, quoting “Hooky” in the subject
            line.
          </p>

          <p>
            <b>Legal Bases for Processing Your Personal Information</b>
          </p>

          <p>
            The legal bases under the GDPR for our processing of personal data of individuals, which
            are located in European Countries at the time of collection, for each of the processing
            activities described above in Sections 2 and 3 is identified in the chart below.{' '}
          </p>

          <table>
            <tbody>
              <tr>
                <td
                  rowSpan={1}
                  colSpan={1}
                  style={{
                    backgroundColor: 'var(--mantine-color-dark-4)'
                  }}
                >
                  <strong>Processing Activity</strong>
                </td>
                <td
                  rowSpan={1}
                  colSpan={1}
                  style={{
                    backgroundColor: 'var(--mantine-color-dark-4)'
                  }}
                >
                  <strong>Legal Bases for Processing</strong>
                </td>
              </tr>
              <tr>
                <td
                  rowSpan={1}
                  colSpan={1}
                  style={{
                    backgroundColor: 'var(--mantine-color-dark-3)'
                  }}
                >
                  <p>Section 2(h) To Ensure the Security of the Services&nbsp;</p>
                  <p>Section 2(i) To Maintain Legal and Regulatory Compliance</p>
                  <p>
                    Section 3(c) Response to Subpoenas or Court Orders or to Protect Rights and to
                    Comply with Our Policies
                  </p>
                </td>
                <td
                  rowSpan={1}
                  colSpan={1}
                  style={{
                    backgroundColor: 'var(--mantine-color-dark-3)'
                  }}
                >
                  <p>
                    Based on our legal obligations. <em>Article 6(1) lit.(c) GDPR.</em>
                  </p>
                </td>
              </tr>
              <tr>
                <td rowSpan={1} colSpan={1}>
                  <p>Section 2(a) To Fulfill Orders and To Provide Our Services</p>
                  <p>Section 2(b) To Provide You Services-Related Communications</p>
                  <p>Section 2(c) To Provide Customer Support and Respond to Requests</p>
                  <p>Section 2(g) To Enforce Compliance with Our Terms, Agreements and Policies</p>
                  <p>Section 2(i)&nbsp; To Train AI Algorithms</p>
                  <p>Section 3(a) Within Our Corporate Organization</p>
                  <p>Section 3(b) With Our Service Providers</p>
                  <p>Section 3(e) With Marketing Partners</p>
                </td>
                <td rowSpan={1} colSpan={1}>
                  <p>
                    Based on our contract with you or to take steps at your request prior to
                    entering a contract. <em>Article 6(1) lit.(b) GDPR.</em>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  rowSpan={1}
                  colSpan={1}
                  style={{
                    backgroundColor: 'var(--mantine-color-dark-3)'
                  }}
                >
                  <p>Section 2(d) To Research and Develop Our Services</p>
                  <p>Section 2(e) To Personalize Your Experience</p>
                  <p>Section 2(f) To Facilitate Corporate Acquisitions, Mergers and Transactions</p>
                  <p>Section 3(d) During Business Transfers; Bankruptcy</p>
                </td>
                <td
                  rowSpan={1}
                  colSpan={1}
                  style={{
                    backgroundColor: 'var(--mantine-color-dark-3)'
                  }}
                >
                  <p>
                    Based on our legitimate interest to operate our business and not overridden by
                    your data protection interests or fundamental rights and freedom.{' '}
                    <em>Article 6(1) lit.(f) GDPR.</em>
                  </p>
                </td>
              </tr>
              <tr>
                <td rowSpan={1} colSpan={1}>
                  <p>Section 2(c) To Provide Marketing Communication</p>
                  <p>Section 2(j) With Your Consent</p>
                  <p>Section 3(f) Disclosure With Your Consent</p>
                </td>
                <td rowSpan={1} colSpan={1}>
                  <p>
                    Based on your consent. <em>Article 6(1) lit.(a) GDPR.</em>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            <b>Privacy Rights</b>
          </p>

          <p>
            If you reside in the European Countries, you may have the right to request access to
            your personal data; right to request correct/rectification of inaccurate personal data;
            (c) right to delete/erasure your personal data; (d) right to portability; (f) right to
            opt-out of automated decision-making or profiling; (g) right tor restrict the processing
            of your personal data; and (i) the right to object to processing your personal data,
            such as for direct marketing purposes.
          </p>

          <p>
            Please note that your rights in relation to your personal information are not absolute.
            Depending upon the applicable law, access to your rights under the applicable law may be
            denied: (a) when denial of access is required or authorized by law; (b) when granting
            access would have a negative impact on another's privacy; (c) to protect our rights and
            properties; or (d) where the request is frivolous or vexatious, or for other reasons
          </p>

          <p>
            <b>Right to Lodge a Complaint to Your Local Data Protection Authority</b>
          </p>

          <p>
            If you reside in the European Countries you may have the right to lodge a complaint with
            your national data protection authority or equivalent regulatory body. You can find
            details of EEA Supervisory Authorities here:
            <a href="https://edpb.europa.eu/about-edpb/board/members_en" target="_blank">
              https://edpb.europa.eu/about-edpb/board/members_en
            </a>
            . The Supervisory Authority in the UK is the Information Commissioner’s Office (ICO).
          </p>

          <p>
            <b>International Transfer of Your Personal Information</b>
          </p>

          <p>
            We rely primarily on the European Commission’s Standard Contractual Clauses to
            facilitate the international and onward transfer of personal information collected in
            the EEA, the United Kingdom and Switzerland (collectively “
            <b>European Personal Information</b>”), to the extent the businesses receiving he
            European Personal Information are located in a country that the European Countries
            consider to not provide an adequate level of data protection. We may also rely on an
            adequacy decision of the relevant regulatory body confirming an adequate level of data
            protection in the jurisdiction of the party receiving the information, or derogations in
            specific situations.
          </p>
        </div>

        <div id="contact-us">
          <h2>HOW TO CONTACT US</h2>

          <p>
            If you have any questions or complaints regarding this Privacy Policy, please contact us
            by sending an email to <b>privacy@hooky.co</b>. We will investigate your question,
            respond to your inquiry, and attempt to resolve any concerns regarding your privacy
            question. If we need, or are required, to contact you concerning any event that involves
            your personal information we may do so by e-mail, telephone, or mail.
          </p>
        </div>
      </div>
    </div>
  );
};
