import { FC } from 'react';
import { Avatar } from '@mantine/core';

import defaultSongImage from '@/assets/default-song-image.png';

interface NameCellProps {
  title: string;
  avatarUrl?: string;
  subtitle?: string;
}

export const NameCell: FC<NameCellProps> = ({ title, avatarUrl, subtitle }) => (
  <div data-content="cell-name" className="flex gap-x-3">
    <Avatar src={avatarUrl || defaultSongImage} alt={title} size={44} />

    <div className="flex flex-col justify-center">
      <div className="text-black dark:text-white text-md font-bold">{title}</div>
      {subtitle ? (
        <div
          className="text-hookybase-200 dark:text-hookybase-300 text-sm"
          data-cell-hover="text-white"
        >
          {subtitle}
        </div>
      ) : null}
    </div>
  </div>
);
