import React from 'react';

const VisibilityOnIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0002 21.3334C17.6668 21.3334 19.0835 20.75 20.2502 19.5834C21.4168 18.4167 22.0002 17 22.0002 15.3334C22.0002 13.6667 21.4168 12.25 20.2502 11.0834C19.0835 9.91671 17.6668 9.33337 16.0002 9.33337C14.3335 9.33337 12.9168 9.91671 11.7502 11.0834C10.5835 12.25 10.0002 13.6667 10.0002 15.3334C10.0002 17 10.5835 18.4167 11.7502 19.5834C12.9168 20.75 14.3335 21.3334 16.0002 21.3334ZM16.0002 18.9334C15.0002 18.9334 14.1502 18.5834 13.4502 17.8834C12.7502 17.1834 12.4002 16.3334 12.4002 15.3334C12.4002 14.3334 12.7502 13.4834 13.4502 12.7834C14.1502 12.0834 15.0002 11.7334 16.0002 11.7334C17.0002 11.7334 17.8502 12.0834 18.5502 12.7834C19.2502 13.4834 19.6002 14.3334 19.6002 15.3334C19.6002 16.3334 19.2502 17.1834 18.5502 17.8834C17.8502 18.5834 17.0002 18.9334 16.0002 18.9334ZM16.0002 25.3334C12.7557 25.3334 9.80016 24.4278 7.1335 22.6167C4.46683 20.8056 2.5335 18.3778 1.3335 15.3334C2.5335 12.2889 4.46683 9.86115 7.1335 8.05004C9.80016 6.23893 12.7557 5.33337 16.0002 5.33337C19.2446 5.33337 22.2002 6.23893 24.8668 8.05004C27.5335 9.86115 29.4668 12.2889 30.6668 15.3334C29.4668 18.3778 27.5335 20.8056 24.8668 22.6167C22.2002 24.4278 19.2446 25.3334 16.0002 25.3334Z"
        fill="currentColor"
      />
    </svg>
  );
};

VisibilityOnIcon.displayName = 'VisibilityOnIcon';

export default VisibilityOnIcon;
