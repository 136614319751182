import { FC, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { Footer, Nav, Typo, UserBar } from '@/components';
import AccountAsideSections from '@/pages/Account/AccountAsideSections';
import { InfoIcon } from '@/assets/icons';
import { ROUTES } from '@/routes/routes.tsx';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

interface AccountLayoutProps {
  children: ReactNode;
  title: string;
  subtitle?: string;
  contentWrapperClassName?: string;
}

const AccountLayout: FC<AccountLayoutProps> = ({
  children,
  title,
  subtitle,
  contentWrapperClassName
}) => {
  const theme = useMantineTheme();
  const md = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);
  const location = useLocation();
  const navigate = useNavigate();

  const isRootAccountRoute = location.pathname === ROUTES.ACCOUNT;

  const navigateToAccount = () => navigate(ROUTES.ACCOUNT);

  return (
    <>
      <main className="flex flex-col flex-1 pl-0 md:pl-20 py-4 md:py-6 relative">
        <UserBar isBackArrowShown={!isRootAccountRoute} handleBackClick={navigateToAccount} />
        <Nav />
        <section className="grid grid-cols-1 md:grid-cols-6 relative">
          <div className="row-start-1 md:col-start-3 md:col-end-7 ml-4 mr-4 lg:ml-16 lg:ml-14">
            <Typo.h1 className={!subtitle ? 'mb-5' : 'mb-2'}>{title}</Typo.h1>
            {!!subtitle && (
              <div className="flex mb-6">
                <p className="text-[0.875rem] leading-[1.125rem] text-hookybase-300 ">{subtitle}</p>
                <InfoIcon width="1rem" height="1rem" className="w-4 ml-1.5" />
              </div>
            )}
          </div>
          <aside
            className={twMerge(
              'col-start-1 col-end-3 md:row-start-2 ml-4 mr-4 lg:ml-16 lg:mr-14',
              !md && isRootAccountRoute ? 'block' : '',
              !md && !isRootAccountRoute ? 'hidden' : ''
            )}
          >
            <AccountAsideSections />
          </aside>
          <section
            className={twMerge(
              'relative md:col-start-3 md:col-end-7  ml-4 mr-4 lg:ml-14 lg:mr-16 overflow-auto',
              !md && isRootAccountRoute ? 'hidden' : '',
              !md && !isRootAccountRoute ? 'block' : ''
            )}
          >
            <div
              className={twMerge(
                'p-6 bg-hookybase-50 dark:bg-hookybase-500 rounded mb-9',
                contentWrapperClassName
              )}
            >
              {children}
            </div>
          </section>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default AccountLayout;
