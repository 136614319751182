import { MantineProvider } from '@mantine/core';
import { CookiesProvider } from 'react-cookie';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { AppProvider, AuthProvider } from '@/providers';
import { AudioProvider, ThemeProvider, mantineTheme } from '@/providers';

import { Routing } from '@/routes';

import 'react-toastify/dist/ReactToastify.css';
import '@mantine/dates/styles.css';
import '@mantine/core/styles.css';

import '@/index.css';

const App = () => {
  const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
  return (
    <MantineProvider theme={mantineTheme}>
      <ThemeProvider>
        <AppProvider>
          <CookiesProvider>
            <AuthProvider>
              <AudioProvider>
                <Elements stripe={stripePromise}>
                  <Routing />
                </Elements>
              </AudioProvider>
            </AuthProvider>
          </CookiesProvider>
        </AppProvider>
      </ThemeProvider>
    </MantineProvider>
  );
};

export default App;
