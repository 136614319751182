import React from 'react';

const EditIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 20.1667V17.0333H16V20.1667H4.5ZM4.5 13.9V10.7667H21.3V13.9H4.5ZM4.5 7.63334V4.46667H21.3V7.63334H4.5ZM17.2 27.5V22.7L24.7 15.2333C24.9444 14.985 25.2185 14.8007 25.5222 14.6804C25.8259 14.5601 26.1407 14.5 26.4667 14.5C26.7778 14.5 27.0894 14.5667 27.4016 14.7C27.7138 14.8333 27.991 15.0222 28.2333 15.2667L29.4667 16.5C29.7072 16.7445 29.8896 17.0185 30.0137 17.3222C30.1379 17.6259 30.2 17.9296 30.2 18.2333C30.2 18.5667 30.1333 18.8889 30 19.2C29.8667 19.5111 29.6788 19.7889 29.4363 20.0333L22 27.5H17.2ZM26.7 19.3L27.7 18.2333L26.4667 17L25.4333 18.0333L26.7 19.3Z"
        fill="currentColor"
      />
    </svg>
  );
};

EditIcon.displayName = 'EditIcon';

export default EditIcon;
