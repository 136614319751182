import React from 'react';

const NonCommercialLicenseIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6665 24L15.9998 19.9333L21.3332 24L19.3332 17.4L24.6665 13.6H18.1332L15.9998 6.66663L13.8665 13.6H7.33317L12.6665 17.4L10.6665 24ZM15.9998 29.3333C14.1554 29.3333 12.4221 28.9833 10.7998 28.2833C9.17761 27.5833 7.7665 26.6333 6.5665 25.4333C5.3665 24.2333 4.4165 22.8222 3.7165 21.2C3.0165 19.5777 2.6665 17.8444 2.6665 16C2.6665 14.1555 3.0165 12.4222 3.7165 10.8C4.4165 9.17774 5.3665 7.76663 6.5665 6.56663C7.7665 5.36663 9.17761 4.41663 10.7998 3.71663C12.4221 3.01663 14.1554 2.66663 15.9998 2.66663C17.8443 2.66663 19.5776 3.01663 21.1998 3.71663C22.8221 4.41663 24.2332 5.36663 25.4332 6.56663C26.6332 7.76663 27.5832 9.17774 28.2832 10.8C28.9832 12.4222 29.3332 14.1555 29.3332 16C29.3332 17.8444 28.9832 19.5777 28.2832 21.2C27.5832 22.8222 26.6332 24.2333 25.4332 25.4333C24.2332 26.6333 22.8221 27.5833 21.1998 28.2833C19.5776 28.9833 17.8443 29.3333 15.9998 29.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

NonCommercialLicenseIcon.displayName = 'NonCommercialLicenseIcon';

export default NonCommercialLicenseIcon;
