import { useModalStore, SubscriptionModalOptions } from '@/store';
import { useGetCardDetail, useGetPlans, useGetSubscription } from '@/hooks';
import { Plan, PlanNames } from '@/models/Plan';
import AccountLayout from '@/layouts/AccountLayout';
import { ModalConfirm, OneButtonProps, Tab, Tabs } from '@/components';
import {
  getPrevCardLabel,
  fremiumPlans,
  enterprisePlans,
  plusProPlans,
  SubscriptionPlanCard
} from '@/components/molecules/SubscriptionPlanCard';

import { CreditCardDetailsModal, CREDIT_CARD_DETAILS_MODAL_NAME } from './CreditCardDetailsModal';
import {
  ConfirmSubscriptionModal,
  CONFIRM_SUBSCRIPTION_CHANGE_MODAL,
  SUCCESS_SUBSCRIPTION_CHANGE_MODAL
} from './ConfirmSubscriptionModal';
import { ENTERPRISE_REQUEST_MODAL, EnterpriseModal } from './EnterpriseModal';
import styles from './subscription.module.css';

const ENTERPRISE_PLAN: Plan = {
  id: 'enterprise',
  name: 'Enterprise',
  description: 'For commercial use',
  amount: 0,
  interval: 'month',
  highlights: ['We offer customizable plans tailored to your needs.'],
  trial_days: null,
  legal: null,
  link_label: null,
  link_url: null,
  isCustom: true
};
const BUTTON_CONFIRM_PROPS: OneButtonProps = {
  text: 'Close',
  variant: 'filled',
  background: 'primary',
  size: 'lg'
};

const AccountSubscription = () => {
  const { openModal, closeModal } = useModalStore();

  const { data: basePlans = [] } = useGetPlans();
  const plans = [...basePlans, ENTERPRISE_PLAN];
  const { data: currentSubscription } = useGetSubscription();
  const { data: cardDetails } = useGetCardDetail();

  const onSelect = (id: string, name: string) => {
    if (name === 'Enterprise') {
      openModal({
        name: ENTERPRISE_REQUEST_MODAL
      });

      return;
    }

    if (cardDetails) {
      const data = plans?.reduce(
        (acc, plan) => {
          if (plan.id === id) {
            acc.selected = {
              id,
              name: plan.name,
              amount: plan.amount,
              interval: plan.interval
            };
          } else if (plan.id === currentSubscription?.plan_id) {
            acc.current = {
              name: plan.name,
              amount: plan.amount,
              interval: plan.interval
            };
          }
          return acc;
        },
        { current: {}, selected: {} } as SubscriptionModalOptions
      );

      openModal({
        name: CONFIRM_SUBSCRIPTION_CHANGE_MODAL,
        data
      });
    } else {
      openModal({ name: CREDIT_CARD_DETAILS_MODAL_NAME, selectedPlanId: id });
    }
  };

  const filterBy = (filters: Record<string, string | PlanNames[]>) =>
    plans?.filter((plan) =>
      Object.keys(filters).every((key) => {
        if (!filters[key].length) {
          return true;
        }
        return (filters as unknown as Record<string, string | string[]>)[key].includes(
          (plan as unknown as Record<string, string>)[key]
        );
      })
    );

  return (
    <AccountLayout title="Available plans">
      <Tabs className={styles.planTabs}>
        <Tab label="All" data-testid="allSubscriptions" className={styles.planTab}>
          {plans?.map((card, index) => (
            <SubscriptionPlanCard
              key={index}
              isHorizontal={true}
              card={card}
              currentPlanId={currentSubscription?.plan_id}
              onSelect={onSelect}
              prevCardLabel={getPrevCardLabel(plans, index)}
            />
          ))}
        </Tab>

        <Tab label="Freemium" data-testid="freemiumSubscriptions" className={styles.planTab}>
          {filterBy({ name: fremiumPlans })?.map((card, index) => (
            <SubscriptionPlanCard
              key={index}
              isHorizontal={true}
              card={card}
              currentPlanId={currentSubscription?.plan_id}
              onSelect={onSelect}
              prevCardLabel={getPrevCardLabel(plans, index)}
            />
          ))}
        </Tab>

        <Tab label="Plus & Pro" data-testid="plusProSubscriptions" className={styles.planTab}>
          {filterBy({ name: plusProPlans })?.map((card, index) => (
            <SubscriptionPlanCard
              key={index}
              isHorizontal={true}
              card={card}
              currentPlanId={currentSubscription?.plan_id}
              onSelect={onSelect}
              prevCardLabel={getPrevCardLabel(plans, index)}
            />
          ))}
        </Tab>

        <Tab label="Enterprise" data-testid="enterpriseSubscriptions" className={styles.planTab}>
          {filterBy({ name: enterprisePlans })?.map((card, index) => (
            <SubscriptionPlanCard
              key={index}
              isHorizontal={true}
              card={card}
              currentPlanId={currentSubscription?.plan_id}
              onSelect={onSelect}
              prevCardLabel={getPrevCardLabel(plans, index)}
            />
          ))}
        </Tab>
      </Tabs>

      <CreditCardDetailsModal />
      <ConfirmSubscriptionModal />
      <EnterpriseModal />

      <ModalConfirm
        name={SUCCESS_SUBSCRIPTION_CHANGE_MODAL}
        subtitle="You have successfully upgraded/downgraded your subscription plan."
        title="Success!"
        onConfirm={closeModal}
        oneButton={BUTTON_CONFIRM_PROPS}
      />
    </AccountLayout>
  );
};
export default AccountSubscription;
