import React from 'react';

const VisaIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="44"
      height="28"
      viewBox="0 0 44 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="44" height="28" rx="5.73134" fill="white" />
      <path
        d="M21.731 9.43703L19.6787 18.9134H17.197L19.2496 9.43703H21.731ZM32.1723 15.556L33.4788 11.9973L34.2307 15.556H32.1723ZM34.9412 18.9134H37.2367L35.2336 9.43703H33.1148C32.6387 9.43703 32.237 9.71057 32.0581 10.1323L28.3349 18.9134H30.941L31.4584 17.4983H34.6424L34.9412 18.9134ZM28.4642 15.8193C28.4748 13.3182 24.9626 13.1805 24.9869 12.0632C24.9942 11.7228 25.3225 11.3617 26.0393 11.269C26.3955 11.2231 27.3748 11.188 28.4867 11.6934L28.9221 9.68388C28.3249 9.46973 27.5563 9.26367 26.6005 9.26367C24.1477 9.26367 22.4212 10.5519 22.4066 12.3965C22.3911 13.7606 23.6391 14.5218 24.5794 14.9753C25.5462 15.4399 25.8711 15.7374 25.8669 16.1528C25.8605 16.789 25.0956 17.0691 24.3821 17.0802C23.1341 17.0997 22.4099 16.7476 21.8327 16.4819L21.383 18.5583C21.9627 18.8213 23.033 19.0499 24.1434 19.0616C26.7502 19.0616 28.4557 17.7893 28.4642 15.8193ZM18.1848 9.43703L14.164 18.9134H11.5402L9.56167 11.3506C9.44143 10.8848 9.33697 10.7144 8.97169 10.518C8.37565 10.1986 7.39033 9.89833 6.52344 9.71237L6.58265 9.43703H10.8054C11.3434 9.43703 11.8277 9.79095 11.9498 10.4031L12.9949 15.8871L15.5774 9.43703H18.1848Z"
        fill="#1434CB"
      />
    </svg>
  );
};

VisaIcon.displayName = 'VisaIcon';

export default VisaIcon;
