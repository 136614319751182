import React from 'react';

const GoogleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_13_674)">
        <path
          d="M6.69612 16.0036C6.69612 14.9634 6.87468 13.9668 7.18717 13.0357L1.68147 8.91112C0.602651 11.0498 0 13.4503 0 16.0036C0 18.5569 0.60265 20.9575 1.67403 23.0889L7.17973 18.9643C6.86724 18.0332 6.69612 17.0439 6.69612 16.0036Z"
          fill="#FBBC05"
        />
        <path
          d="M16.3683 6.54694C18.6747 6.54694 20.758 7.34712 22.3948 8.65651L27.1565 4.00091C24.2548 1.52762 20.5348 0 16.3683 0C9.90281 0 4.3376 3.62264 1.68147 8.91112L7.18717 13.0284C8.45943 9.25301 12.0753 6.53967 16.3683 6.53967V6.54694Z"
          fill="#EA4335"
        />
        <path
          d="M16.3683 25.4603C12.0753 25.4603 8.45943 22.747 7.18717 18.9716L1.68147 23.0889C4.34504 28.3846 9.90281 32 16.3683 32C20.3636 32 24.173 30.6106 27.03 28.0136L21.7996 24.0564C20.3264 24.9657 18.4664 25.4531 16.3608 25.4531L16.3683 25.4603Z"
          fill="#34A853"
        />
        <path d="M7.17973 18.9643L1.67403 23.0889L7.17973 18.9716V18.957V18.9643Z" fill="#34A853" />
        <path
          d="M16.3683 13.0939V19.2771H25.1476C24.7087 21.3867 23.5108 23.0089 21.807 24.0637L27.0374 28.0209C30.0432 25.293 32 21.2194 32 16.0036C32 15.058 31.8512 14.0396 31.628 13.0939H16.3683Z"
          fill="#4285F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_13_674">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

GoogleIcon.displayName = 'GoogleIcon';

export default GoogleIcon;
