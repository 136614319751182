import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes';
import Account from '@/pages/Account';
import PaymentDetails from '@/pages/Account/Payment';
import AccountProfile from '@/pages/Account/Profile';
import AccountSecurity from '@/pages/Account/Security';
import AccountDelete from '@/pages/Account/Delete';
import AccountSubscription from '@/pages/Account/Subscription';
import ArtistApproval from '@/pages/Account/ArtistApproval';
import ChangePassword from '@/pages/Account/Security/ChangePassword';
import { PermissionRoute } from '@/routes/PermissionRoute';
import { ROLES } from '@/models/User';
import SiteTermsLayout from '@/layouts/SiteTermsLayout';
import CookiePolicy from '@/pages/SiteTerms/CookiePolicy';
import TermsService from '@/pages/SiteTerms/TermsService';
import PrivacyPolicy from '@/pages/SiteTerms/PrivacyPolicy';

import NotAuthorized from '@/pages/NotAuthorized';

const Error = lazy(() => import('@/pages/Error'));

//TODO: Lazy loading is removed for all Account Components as on switching between them on loading component on dark theme user sees short white flash.
// Think to bring lazy loading back and introduce Suspense component which would render black background on dark theme or white on light theme
const Approvals = lazy(() => import('@/pages/Approvals'));
const Artists = lazy(() => import('@/pages/Artists'));
const Create = lazy(() => import('@/pages/Create'));
const Projects = lazy(() => import('@/pages/Projects/ProjectsSongs'));
const ProjectsCollaborations = lazy(() => import('@/pages/Projects/ProjectsСollaborations'));
const ProjectsLibrary = lazy(() => import('@/pages/Projects/ProjectsLibrary'));
// const Releases = lazy(() => import('@/pages/Releases'));
const Dashboard = lazy(() => import('@/pages/Dashboard'));

const EmailRedirect = lazy(() => import('@/pages/EmailRedirect'));

const ProtectedLayout = lazy(() => import('@/layouts/ProtectedLayout'));

const ARTIST_PERMISSIONS = [ROLES.ARTISTS, ROLES.ADMIN];

const protectedRouting = (
  <Route element={<ProtectedLayout />}>
    <Route path={ROUTES.DASHBOARD_HOME} element={<Navigate to={ROUTES.DASHBOARD} replace />} />
    <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
    <Route path={ROUTES.ACCOUNT} element={<Account />} />
    <Route path={ROUTES.ACCOUNT_HOME} element={<Account />} />
    <Route path={ROUTES.ACCOUNT_PROFILE} element={<AccountProfile />} />
    <Route path={ROUTES.ACCOUNT_ARTIST_APPROVAL} element={<ArtistApproval />} />
    <Route path={ROUTES.ACCOUNT_SECURITY} element={<AccountSecurity />} />
    <Route path={ROUTES.ACCOUNT_CHANGE_PASSWORD} element={<ChangePassword />} />
    <Route path={ROUTES.ACCOUNT_SUBSCRIPTION} element={<AccountSubscription />} />
    <Route path={ROUTES.ACCOUNT_PAYMENT_DETAILS} element={<PaymentDetails />} />
    <Route path={ROUTES.ACCOUNT_DELETE} element={<AccountDelete />} />
    <Route
      path={ROUTES.APPROVALS}
      element={
        <PermissionRoute permissions={ARTIST_PERMISSIONS}>
          <Approvals />
        </PermissionRoute>
      }
    />
    <Route path={ROUTES.ARTISTS} element={<Artists />} />
    <Route path={`${ROUTES.CREATE}/:voiceId`} element={<Create />} />
    <Route path={ROUTES.PROJECTS} element={<Projects />} />
    <Route path={ROUTES.PROJECTS_COLLABORATIONS} element={<ProjectsCollaborations />} />
    <Route path={ROUTES.PROJECTS_LIBRARY} element={<ProjectsLibrary />} />
    {/* TODO: This page is hidden until release analytic is implemented on BE side*/}
    {/*<Route path={ROUTES.RELEASES} element={<Releases />} />*/}

    <Route path={ROUTES.SITE_TERMS} element={<SiteTermsLayout />}>
      <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={ROUTES.TERMS_OF_USE} element={<TermsService />} />
      <Route path={ROUTES.SUBSCRIPTIONS_TERMS} element={<CookiePolicy />} />
      <Route path={ROUTES.COOKIE_POLICY} element={<CookiePolicy />} />
    </Route>

    <Route path={ROUTES.NOT_AUTHORIZED} element={<NotAuthorized />} />
  </Route>
);

export const Routing = () => {
  return (
    <Routes>
      {protectedRouting}

      <Route path={ROUTES.COLLABORATIONS} element={<EmailRedirect />} />

      <Route path="*" element={<Error />} />
    </Routes>
  );
};
