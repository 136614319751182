import { ScrollArea } from '@mantine/core';

import { ModalFooterBtn, Modal } from '@/components';
import { AGREED_TERMS_UPLOAD_TRACK_MODAL_NAME, UPLOAD_MASTER_MODAL_NAME } from '@/constants/song';
import { useModalStore } from '@/store';

import contentStyles from '@/pages/SiteTerms/site-terms.module.css';
import styles from './agreed-terms-upload-track-modal.module.css';
import { useGetAccount } from '@/hooks';

interface ModalData {
  voiceId: string;
  readOnly: boolean;
  title: string;
  artistName: string;
  split: string;
  voiceAI: string;
}

export const AgreedTermsUploadTrackModal = () => {
  const { data: account } = useGetAccount();
  const { closeModal, openModal, modalOption } = useModalStore();
  const { voiceId, readOnly, title, artistName, split, voiceAI } =
    modalOption as unknown as ModalData;

  const handleAgreed = () => {
    if (readOnly) {
      closeModal();

      return;
    }

    closeModal();

    openModal({ name: UPLOAD_MASTER_MODAL_NAME, voiceId });
  };

  const footerBtns: ModalFooterBtn[] = [
    {
      key: 'submit',
      onClick: handleAgreed,
      content: 'ACCEPT TERMS',
      disabled: Boolean(readOnly),
      background: readOnly ? 'secondary' : 'primary'
    }
  ];

  const date = new Date().toLocaleString().split(',')[0];

  return (
    <Modal
      name={AGREED_TERMS_UPLOAD_TRACK_MODAL_NAME}
      title="License term sheet agreement"
      size="750px"
      footerBtns={footerBtns}
      isCentered
    >
      <div className={styles.content}>
        <ScrollArea.Autosize type="scroll" mah={450}>
          <div className={contentStyles.content}>
            <p>
              THIS NEW TRACK AGREEMENT, effective as of {date} (the “<u>Effective Date</u>”), by and
              between <b>Hooky AI, Inc</b>., located at 100 W Broadway Ste 750. Glendale CA 91210 (“
              <u>Hooky</u>”), the Creator identified below, and the Label/Artist identified below,
              sets forth the parties’ rights and obligations relating to the new sound recording
              embodying a musical composition with the track title listed below (the “<u>Track</u>
              ”), in which an AI-generated vocal performance based on a voice model of Voice Artist
              identified below was incorporated into Creator’s music using Hooky’s AI platform (the
              “<u>Platform</u>”) for distribution by Hooky and other exploitation as set forth
              below.
            </p>

            <h2>KEY TERMS</h2>

            <table className="my-4">
              <tbody>
                <tr>
                  <td width="35%">
                    <b>1. Track Title:</b>
                  </td>
                  <td width="65%">{title as string}</td>
                </tr>

                <tr>
                  <td width="35%">
                    <b>2. Creator Name, Address, and Email:</b>
                  </td>
                  <td width="65%">
                    {account?.display_name}, {account?.email} (“Creator”)
                  </td>
                </tr>

                <tr>
                  <td width="35%">
                    <b>3. Label/Artist Name:</b>
                  </td>
                  <td width="65%">{artistName} (“Label/Artist”)</td>
                </tr>

                <tr>
                  <td width="35%">
                    <b>4. Name of Artist Contributing AI Vocal Performance:</b>
                  </td>
                  <td width="65%">{voiceAI} AI</td>
                </tr>

                <tr>
                  <td width="35%">
                    <b>5. Track Artist Credit:</b>
                  </td>
                  <td width="65%">{artistName} (the “Track Artist Credit”)</td>
                </tr>

                <tr>
                  <td width="35%">
                    <b>6. Copyright Splits:</b>
                  </td>
                  <td width="65%">
                    <div>(a) Label/Artist: {split}</div>
                    <div>(b) Creator: {account?.display_name}</div>
                  </td>
                </tr>

                <tr>
                  <td width="35%">
                    <b>7. Royalty Splits (of Net Revenue):</b>
                  </td>
                  <td width="65%">
                    <div>(a) Label/Artist: {split}</div>
                    <div>(b) Creator: {account?.display_name}</div>
                  </td>
                </tr>

                <tr>
                  <td width="35%">
                    <b>7. Territory:</b>
                  </td>
                  <td width="65%">Worldwide (the “Territory”)</td>
                </tr>
              </tbody>
            </table>

            <p>
              For good and valuable consideration, the receipt and sufficiency of which is hereby
              acknowledged, effective as of the Effective Date following signature of this Agreement
              by Creator and approval of this Agreement by Label/Artist, the parties agree to the
              Key Terms set forth above (the “<u>Key Terms</u>”) and the attached Standard Terms
              (the “<u>Standard Terms</u>”), all of which will collectively constitute the “
              <u>Agreement</u>”
            </p>

            <h2>STANDARD TERMS</h2>

            <ul>
              <li>
                <p>
                  <u>Copyright Assignment:</u>
                </p>

                <ol type="a">
                  <li>
                    <p>
                      <u>To Label/Artist.</u>
                    </p>

                    <p>
                      Creator hereby, effective as of the Effective Date, irrevocably and
                      unconditionally transfers, conveys, grants, assigns and delivers to
                      Label/Artist and its successors and/or assigns, as of the Effective Date, an
                      undivided interest, in the percentage set forth in section 6(a) of the Key
                      Terms above, of Creator’s right, title and interest (whether vested,
                      contingent, inchoate, expectant or otherwise) in and to the sound recording
                      embodied in the Track. The foregoing assignment, transfer, and conveyance
                      includes, without limitation, the applicable percentage of Creator’s entire
                      right, title and interest throughout the world in and to the copyright rights
                      and all rights incident thereto and derivatives thereof, reversionary rights
                      (solely to the extent permissible under U.S. copyright law) and other rights
                      relating to the sound recording embodied in the Track now known or that may
                      hereafter be recognized or come into existence, and any and all renewals and
                      extensions of such copyrights and other rights under all applicable laws,
                      treaties, regulations and directives now or hereafter enacted or in effect.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Copyright Retained by Creator.</u>
                    </p>

                    <p>
                      For the avoidance of doubt, Creator retains, for itself and its successors
                      and/or assigns, (i) an undivided interest in the percentage set forth in
                      section 6(b) of the Key Terms above of Creator’s right, title and interest
                      (whether vested, contingent, inchoate, expectant or otherwise) in and to the
                      sound recording embodied in the Track, and (ii) an undivided 100% of all of
                      Creator’s right, title and interest (whether vested, contingent, inchoate,
                      expectant or otherwise) in and to the musical composition embodied in the
                      Track.
                    </p>
                  </li>
                </ol>
              </li>

              <li>
                <p>
                  <u>Additional Rights:</u>
                </p>

                <ol type="a">
                  <li>
                    <p>
                      <u>Rights Granted.</u>
                    </p>

                    <p>
                      Creator hereby grants to Hooky, on an exclusive and sublicensable basis,
                      throughout the Territory, the rights to, and to authorize Distribution
                      Aggregator(s) and DSPs to:
                    </p>

                    <ol type="i">
                      <li>
                        <p>copy, reproduce, display, host and distribute the Track via DSPs;</p>
                      </li>

                      <li>
                        <p>
                          stream (whether on an interactive or non-interactive basis) and/or deliver
                          conditional downloads and/or permanent downloads of the Track (in whole or
                          in part), on a through to the end user basis, in each case, across any and
                          all apps, devices, networks, platforms, services, stores, and/or websites
                          owned or controlled by DSPs, now known or hereinafter developed, on a
                          commercial or promotional basis;
                        </p>
                      </li>

                      <li>
                        <p>
                          reproduce, display and otherwise use and exploit Creator Content
                          (including, for avoidance of doubt, the names, images, likenesses and
                          biographical information of Creator and any other performing and/or
                          recording artists, producers, engineers, mixers and remixers, and all
                          other persons and entities contributing to the recording and production of
                          the Track other than Voice Artist (collectively, “Other Personnel”)) for
                          and in connection with promoting and marketing the availability of the
                          Track on the DSPs and marketing, promotion and publicity for the Platform
                          and the DSPs;
                        </p>
                      </li>

                      <li>
                        <p>
                          make claims in respect of third party uses of the Track (in whole or in
                          part) and block, permit and/or monetize such uses, in each case, across
                          any and all DSPs;
                        </p>
                      </li>

                      <li>
                        <p>
                          create fingerprints of, reference files relating to, metadata concerning
                          and/or other digital attributes of the Track to facilitate the exercise of
                          the rights granted herein to Hooky;
                        </p>
                      </li>

                      <li>
                        <p>
                          make such other uses of the Track and the Creator Content (in whole or in
                          part) and otherwise exploit, modify, format, reformat, excerpt, encode,
                          host, cache, transmit, distribute, store, copy, embed, upload, perform,
                          communicate to the public, display, render, sell copies of, sell
                          advertising against and/or subscription access to (or otherwise monetize),
                          block, disable, and/or take down, the Track and the Creator Content (in
                          whole or in part), solely to facilitate the exercise of the rights granted
                          herein to Hooky
                        </p>
                      </li>

                      <li>
                        <p>
                          collect and administer all revenues generated in connection with the
                          exploitation of the sound recording rights in the Track via DSPs, and
                          manage and distribute royalties in connection therewith, as further set
                          forth herein.
                        </p>
                      </li>
                    </ol>
                  </li>

                  <li>
                    <p>
                      <u>Exclusivity & Creator’s Retained Rights.</u>
                    </p>

                    <p>
                      For the avoidance of doubt, the grant of rights to Hooky herein includes
                      without limitation all rights in and to the sound recording and the musical
                      composition embodied in the Track to the full extent necessary to permit the
                      exercise of such rights, and, notwithstanding anything to the contrary, such
                      grant of rights is exclusive with respect to the sound recording rights in the
                      Track, but non-exclusive with respect to the musical composition rights in the
                      Track. Creator will not have the right to distribute or exploit the Track,
                      except that Creator may post the Track to Creator’s own, Creator-branded
                      social media accounts, provided that Creator shall not have the right to
                      monetize any such posts and that all such posts shall identify the Track using
                      the Track Artist Credit set forth in section 5 of the Key Terms and include
                      all such 3 other attribution, metadata and other information as Hooky may
                      reasonably require, and further provided that, in the event that Creator is a
                      paid subscriber to the Platform, Creator will also have the right to post the
                      Track to YouTube, so long as Creator takes any action necessary and as may be
                      directed by Hooky from time to time (including in Hooky’s Terms of Service) to
                      authorize and otherwise enable Hooky to monetize any such posts. Any claiming
                      and/or monetization of posts on YouTube not in accordance with the foregoing
                      will be deemed a breach of this agreement. Notwithstanding anything to the
                      contrary, Creator acknowledges and agrees that Creator may not engage in any
                      of the foregoing uses or exploitations of the Track until Label/Artist has
                      formally approved the Track.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>No Other Exploitations.</u>
                    </p>

                    <p>
                      For the avoidance of doubt, the parties acknowledge and agree that, except as
                      expressly set forth in this Agreement, no other exploitations of the Track
                      (including without limitation master use rights for use of the Track in
                      audiovisual works) are permitted by any party without the prior written
                      approval of all parties in each party’s sole discretion, and subject to
                      potential new arrangements with respect to economics to be negotiated in good
                      faith by the parties.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Label/Artist Consent. </u>
                    </p>

                    <p>
                      Upon approval by Label/Artist of the Track, Label/Artist, on behalf of itself
                      and Voice Artist, grants to Hooky and Creator, on a non-exclusive, irrevocable
                      basis throughout the Territory, all rights necessary to use and exploit Voice
                      Artist’s voice in the Track, and to use the Track Artist Credit (and, solely
                      to the extent incorporated in the Track Artist Credit, Voice Artist’s name) in
                      connection with the Track, the use and exploitation thereof, and the promotion
                      and marketing of the Track, the Platform and the DSPs, in each case as
                      expressly set forth in this Agreement.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Further Documentation.</u>
                    </p>

                    <p>
                      Upon Hooky’s request, Creator shall deliver or cause to be delivered such
                      additional documents and other papers and to take or cause to be taken such
                      further actions as may reasonably be necessary, proper or advisable, to make
                      effective the rights granted by it or on its behalf to Hooky and Label/Artist
                      hereby and to carry out the provisions hereof.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Formatting, Metadata and Reporting.</u>
                    </p>

                    <p>
                      Without limiting any rights granted hereunder, Hooky will have the rights to:
                      (i) format, reformat and otherwise prepare the Track and Creator Content for
                      distribution to third party digital service providers; (ii) identify, claim,
                      track, flag, register, distribute and monetize the Track, whether in whole or
                      in part (including as embodied in end-user generated content, to the extent
                      matched on third party platforms); (iii) create, edit or modify Metadata for
                      the Track; (iv) add additional categories of information to Metadata, such as
                      genres and biographical information; (v) use such corrected and/or additional
                      Metadata in connection with the Track in any and all media; and (vi) deliver
                      Metadata, Creator Content and/or other content or information that Creator
                      provides to Hooky to DSPs and/or rightsholders or other third parties as
                      necessary in connection with Hooky’s exercise of its rights and performance of
                      its obligations hereunder.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Content Removal.</u>
                    </p>

                    <p>
                      Hooky will have the right to remove or exclude the Track or any specific
                      Creator Content from any DSP in any or all countries of the Territory in its
                      discretion at any time, including without limitation at the request of Voice
                      Artist; if a DSP identifies issues relating to such Track or Creator Content;
                      or to the extent Hooky reasonably believes it does not have sufficient rights
                      to license and distribute such Track or Creator Content.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Third-Party Contractors.</u>
                    </p>

                    <p>
                      Creator acknowledges that Hooky may use third-party contractors, affiliates,
                      subsidiaries and/or or other designees (collectively, “<u>Contractors</u>”) to
                      exercise its rights and/or perform its obligations under this Agreement,
                      provided that Hooky will remain responsible under this Agreement for each
                      applicable third party’s exercise of such rights and/or performance of such
                      obligations.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Rights from Other Sources.</u>
                    </p>

                    <p>
                      For the avoidance of doubt, nothing in this Agreement will prohibit Hooky from
                      exploiting or otherwise using any Creator Content or any information, images
                      or other materials relating to the Track, Creator Content and/or Other
                      Personnel that Hooky has obtained from other sources and/or for which it would
                      not otherwise need consent from Creator to use, display or otherwise exploit
                      under applicable law.
                    </p>
                  </li>
                </ol>
              </li>

              <li>
                <p>
                  <u>Royalties:</u>
                </p>

                <ol type="a">
                  <li>
                    <p>
                      <u>Revenue Share:</u>
                    </p>

                    <p>
                      As consideration for the rights granted herein, subject to the terms and
                      conditions of this Agreement and provided that Creator is not then in breach
                      of this Agreement, and solely to the extent that Creator is a paid subscriber
                      to the Platform, Hooky shall pay to Creator royalties (“<u>Royalties</u>”) as
                      follows: the royalty split percentage designated for Creator in section 7(b)
                      of the Key Terms
                      <u>multiplied by</u> the Net Revenues for the Track in respect of the
                      applicable Accounting Period.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Definitions</u>
                    </p>

                    <ol>
                      <li>
                        <p>
                          “<u>Net Revenues</u>” means the revenues actually received by Hooky from
                          the Distribution Aggregator which are solely and directly attributable to
                          exploitation of the Track in respect of the applicable Accounting Period,
                          less the Hooky Platform Fee for the Track.
                        </p>
                      </li>

                      <li>
                        <p>
                          “<b>Hooky Platform Fee</b>” means twenty percent (20%){' '}
                          <u>multiplied by</u> the revenues received by Hooky from the Distribution
                          Aggregator which are solely and directly attributable to exploitation of
                          the Track in respect of the applicable Accounting Period.
                        </p>
                      </li>
                    </ol>
                  </li>

                  <li>
                    <p>
                      <u>No Other Fees.</u>
                    </p>

                    <p>
                      Other than the Royalties expressly set forth in this Agreement, no additional
                      fees, amounts or consideration of any kind or nature will be owed by or on
                      behalf of Hooky, directly or indirectly, in connection with the use of the
                      Track or the Creator Content or the exercise of the rights granted to Hooky
                      under this Agreement. For the avoidance of doubt, Hooky will not be obligated
                      to pay to or on behalf of Creator or any third party any additional amounts in
                      connection with the storage, hosting, display, encoding, reproduction,
                      communication, performance, making available, transmission, delivery,
                      distribution, exhibition or other permitted use of the Track or the Creator
                      Content in whole or in part, directly or indirectly, including any authorized
                      use of the Track or the Creator Content for purposes of operating, marketing
                      or promoting the Platform.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Royalty Splits</u>
                    </p>

                    <p>
                      For the avoidance of doubt and notwithstanding anything to the contrary,
                      Creator agrees that all revenues generated in connection with exploitation of
                      the sound recording embodied in the Track shall be shared according to this
                      section 3, the royalty splits set forth in section 7 of the Key Terms, and as
                      otherwise set forth herein.
                    </p>
                  </li>
                </ol>
              </li>

              <li>
                <p>
                  <u>Accounting:</u>
                </p>

                <ol type="a">
                  <li>
                    <p>
                      <u>Payment & Accounting.</u>
                    </p>

                    <p>
                      Hooky will calculate Royalties on a calendar monthly basis, or such other
                      periodic basis as Hooky in its sole discretion elects (each such period, an “
                      <u>Accounting Period</u>”). Subject to the terms and conditions of this
                      Agreement, Hooky will pay any Royalties to Creator within 90 days after the
                      applicable Accounting Period. Hooky may, in its sole discretion, calculate
                      Royalties (including all other terms used to calculate Royalties): on a Hooky
                      Partner-by-Hooky Partner basis (including on a tier-by-tier basis to the
                      extent a Hooky Partner offers multiple tiers of service), country-by-country
                      basis and/or on another basis. Hooky will not be required to issue any
                      payments to Creator until the aggregate Royalties payable to Creator total at
                      least $100. Hooky will provide Creator with access to a Royalties accounting
                      statement (the “<u>Accounting Statement</u>”) for each Accounting Period in
                      connection with which Royalties are due, but not for any Accounting Period
                      during which no Royalties are earned and/or generated. Accounting Statements,
                      if any, will be made available for access electronically via the Platform and
                      shall be deemed rendered when so available.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Required Documentation.</u>
                    </p>

                    <p>
                      Notwithstanding anything to the contrary, Hooky has no obligation to pay to
                      Creator or its designee any Royalties hereunder unless and until Creator
                      complies with the following:
                    </p>

                    <ul>
                      <li>
                        <p>
                          Creator will provide to Hooky Creator’s or its designee’s payment details
                          as required by Hooky; and
                        </p>
                      </li>

                      <li>
                        <p>
                          Creator will (and will cause any applicable designee to) complete and
                          provide to Hooky all documentation Hooky reasonably requires to comply
                          with its legal obligations, including without limitation (A) for U.S.
                          taxpayers, a fully completed Form W-9, or (B) for non-U.S. taxpayers, a
                          fully completed W-8.
                        </p>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <p>
                      <u>Taxes.</u>
                    </p>

                    <p>
                      Creator shall be solely responsible for all sales, use, excise, value-added or
                      similar taxes and other amounts as may be required to be imposed under
                      applicable law in connection with the distribution or sale of the Track and/or
                      any Creator Content hereunder. If Hooky is required to deduct or withhold
                      taxes from any amounts payable hereunder, then Hooky may deduct and withhold
                      such taxes from such amounts, without obligation to gross-up payments to or to
                      indemnify Creator or any designee in respect of such deductions or
                      withholdings. In no event will Hooky be liable for any taxes that are based
                      upon Creator’s or any designee’s net or gross income or gross receipts.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Fulfillment</u>
                    </p>

                    <p>
                      Hooky will be deemed to have fulfilled all obligations to pay Royalties
                      hereunder where payment is made to the account Creator (and/or Creator’s
                      designee) designates hereunder or where (i) Creator’s bank or any other third
                      party payment processor (or that of Creator’s designee) rejects any payment(s)
                      (for example, due to reasons specific to the Creator’s geographic region);
                      (ii) Creator, its agent or designee, or such payment triggers an Anti-Money
                      Laundering (AML) and/or Office of Foreign Assets Control (OFAC) review; or
                      (iii) for any other reason under applicable law or governmental regulation
                      such payment is not permissible. In the event that, in connection with any
                      payment details Creator or its designee provides to Hooky (either directly or
                      indirectly through a third party), Hooky is charged any fees and/or penalties
                      by any applicable third party payment processing services (as may be
                      implemented by Hooky from time to time in its sole discretion), Creator hereby
                      agrees that Hooky shall have the right to deduct the full amount(s) of any and
                      all such fees and/or penalties from any and all Royalties which may be due or
                      become due pursuant to this Agreement. Creator hereby agrees not to raise any
                      objection to any and all such deductions, which shall be made in Hooky’s sole
                      discretion.
                    </p>
                  </li>
                </ol>
              </li>

              <li>
                <p>
                  <u>Hooky Services:</u>
                </p>

                <ol type="a">
                  <li>
                    <p>
                      <u>Royalty Management.</u>
                    </p>

                    <p>
                      Hooky will collect, administer and distribute royalties in respect of all
                      exploitations of the Track as delivered to DSPs hereunder in accordance with
                      section 3 of these Standard Terms and the royalty splits set forth in the Key
                      Terms as further set forth herein. HOOKY DOES NOT GUARANTEE THAT THE TRACK OR
                      ANY CREATOR CONTENT WILL GENERATE ANY ROYALTIES.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Distribution</u>
                    </p>

                    <p>
                      Hooky may license and distribute or monetize the Track via any or all digital
                      service providers (“DSPs”) that Hooky may select in its reasonable discretion.
                      Hooky may use one or more third party distribution aggregators, to be selected
                      by Hooky in its sole discretion (each, a “<u>Distribution Aggregator</u>”, and
                      together with the DSPs, “<u>Hooky Partners</u>”), to license and distribute
                      the Track to DSPs with which the Distribution Aggregator has a contractual
                      arrangement. Hooky has sole discretion over the distribution or monetization
                      of the Track, and Creator agrees that Hooky may distribute or monetize the
                      Track (together with applicable Creator Content) on select Hooky Partners’
                      offerings/service tiers or via all or none of the Hooky Partners’
                      offerings/service tiers and in some countries of the Territory or all
                      countries of the Territory. Notwithstanding anything to the contrary, Hooky
                      shall have no obligation to exercise any of the rights granted to it hereunder
                      or to distribute or monetize the Track or any Creator Content via any DSP or
                      any particular DSP or otherwise.
                    </p>
                  </li>
                </ol>
              </li>

              <li>
                <p>
                  <u>Creator’s Obligations:</u>
                </p>

                <ol>
                  <li>
                    <p>
                      <u>Creator Content:</u>
                    </p>

                    <p>
                      To the extent not already delivered to Hooky, promptly after the Effective
                      Date, Creator will deliver to Hooky for use in connection with the
                      exploitation and promotion of the Track and the Platform as set forth herein,
                      to the extent available to Creator (and provided that Hooky may determine
                      whether to use any such materials in its sole discretion): any front-cover
                      artwork, graphics, images and textual materials (including liner notes)
                      associated with the Track (collectively, “<u>Artwork</u>”); all sound
                      recording information, including track duration, featured artist name(s)
                      (other than Voice Artist), ISRC codes, the name(s) of the authors of the
                      musical work and lyrics which are embodied therein, and any other
                      mutually-agreed information relating to the Track (collectively, “
                      <u>Metadata</u>”); the names, logos, brands, trade names, trademarks, service
                      marks or other proprietary designations of Creator and any Other Personnel
                      (the “<u>Creator Marks</u>”); and approved names, images, likenesses and
                      biographical information of Creator and any Other Personnel relating to the
                      Track (collectively, and together with Artwork, Metadata, Creator Marks and
                      any other materials provided or selected by or on behalf of Creator for use on
                      or in connection with the Track and/or the Platform, collectively, “
                      <u>Creator Content</u>”). All Creator Content delivered to Hooky by or on
                      behalf of Creator shall be deemed approved by Creator for all purposes under
                      this Agreement.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Delivery Format/Quality & Other Materials.</u>
                    </p>

                    <p>
                      Creator will make commercially reasonable efforts to deliver all Creator
                      Content to Hooky in the highest quality digital formats available (and, at a
                      minimum, in the same formats and no less than the same quality levels as are
                      generally delivered to digital music services) and in accordance with any
                      delivery and metadata specifications provided by Hooky, except to the extent
                      otherwise mutually agreed between the parties.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Third-Party Rights:</u>
                    </p>

                    <p>
                      As between Creator and Hooky, Creator will be responsible for obtaining,
                      retaining and administering any and all licenses, rights, authorizations,
                      permissions and consents that may be required from, and paying any and all
                      associated royalties and other amounts that may be owed to, any and all third
                      parties (other than Voice Artist) in connection with the exploitation of the
                      Track and the Creator Content by or on behalf of Hooky as authorized by this
                      Agreement, including without limitation with respect to any record label or
                      other third parties who may have any contractual relationship with Creator or
                      any Other Personnel, any applicable publishers, or otherwise in respect of all
                      master recordings and musical compositions used therein. In the event that
                      Hooky is required or elects to pay any such amounts (which Hooky is not
                      obligated to do), then, at Hooky’s election, (i) Creator will reimburse Hooky
                      for all amounts so paid promptly following Hooky’s request, or (ii) Hooky may
                      deduct any or all amounts so paid or payable from any Royalties or other
                      amounts payable to Creator under this Agreement. Creator’s obligations under
                      this section include without limitation:
                    </p>

                    <ul>
                      <li>
                        <p>
                          <u>Track & Creator Content:</u>
                        </p>

                        <p>
                          obtaining all rights, consents, waivers, approvals and permissions from,
                          and paying all royalties or other amounts payable to, artists, producers,
                          songwriters, mixers, engineers, photographers, graphic artists and any and
                          all other persons (including Other Personnel) and entities (other than
                          Label/Artist and Voice Artist) required for all use and exploitation of
                          the Track and Creator Content as permitted by this Agreement; and
                        </p>
                      </li>

                      <li>
                        <p>
                          <u>Guilds, Unions and Collectives:</u>
                        </p>

                        <p>
                          complying with all obligations and paying any and all amounts that may be
                          required pursuant to any and all collective bargaining agreements
                          applicable to Creator and/or any Other Personnel, or that may be otherwise
                          payable to any applicable union or guild, and making any and all payments
                          required to be made to any applicable governmental authority and/or
                          collective, or similar body, including pursuant to any agreement, tariff
                          or law relating to performer’s rights, artist performance rights
                          (including non-featured performers), neighboring rights and/or rights of
                          equitable remuneration, however characterized, as a result of the
                          exploitation or other use of the Track and/or Creator Content as permitted
                          under this Agreement.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>

              <li>
                <p>
                  <u>Certain Representations, Warranties and Covenants:</u>
                </p>

                <ol>
                  <li>
                    <p>
                      <u>By All Parties:</u>
                    </p>

                    <p>
                      As of the Effective Date and on an ongoing basis, each party represents,
                      warrants and covenants that: (i) it has the full right and power to enter into
                      and fully perform this Agreement in accordance with its terms; (ii) its
                      execution of this Agreement will not violate any third-party rights, the
                      provisions of any agreement to which it is a party, or any applicable law; and
                      (iii) except with respect to any obligations which are expressly another
                      party’s responsibility pursuant to this Agreement, it will comply with all
                      applicable laws in the performance of its obligations under this Agreement.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>By Creator.</u>
                    </p>

                    <p>
                      As of the Effective Date and on an ongoing basis, Creator represents, warrants
                      and covenants that: (i) Creator will comply with all regulations and rules of
                      any guilds, unions or other collectives in the performance of its obligations
                      under this Agreement; (ii) the Track and Creator Content, any master
                      recordings and/or musical compositions embodied therein, as well as any and
                      all other materials provided by or on behalf of Creator to or on behalf of
                      Hooky pursuant to this Agreement, and the use thereof by and on behalf of
                      Hooky in accordance with this Agreement, will not violate any law or infringe
                      or violate any proprietary, intellectual property, contract or other rights of
                      any other person or entity, including without limitation copyrights, trademark
                      rights, performer’s rights, rights of publicity and privacy, and any rights of
                      exclusivity; (iii) Creator has full authority to act, in accordance with this
                      Agreement, on behalf of any and all Other Personnel, and any and all other
                      persons and entities owning or controlling any right, title or interest in and
                      to the Track and/or any Creator Content; (iv) Creator owns or controls all
                      rights necessary, including without limitation in any master recordings and/or
                      musical compositions embodied in the Track, to make the grants of rights and
                      licenses herein, and the exercise of such rights and licenses by or on behalf
                      of Hooky, its affiliates and contractors will not violate or infringe the
                      rights of any third party; (v) other than Hooky’s express obligation to pay
                      Royalties hereunder, Hooky will not be required to make any payments of any
                      nature for or in connection with the exercise or exploitation of the rights
                      granted herein, and Creator will be solely responsible for the payment of
                      royalties and any other amounts to any and all recording artists, Other
                      Personnel, record labels, publishers and any other third parties who are
                      entitled to a royalty or other payment in connection with the permitted
                      exploitation of the Track and/or any Creator Content hereunder (other than
                      Label/Artist and Voice Artist); and (vi) without limiting the generality of
                      the foregoing, Creator has obtained, and will maintain on an ongoing basis,
                      all rights, licenses, permissions, clearances, waivers and/or approvals
                      necessary from each person or entity (including without limitation any Other
                      Personnel, record label and/or publisher, but excluding Label/Artist and Voice
                      Artist) that owns or controls any interest in and to the Track, any master
                      recordings and/or musical compositions embodied therein, and/or any Creator
                      Content necessary to make the uses permitted under this Agreement, free of all
                      claims and demands whatsoever, known or unknown, in law or equity.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>By Label/Artist</u>
                    </p>

                    <p>
                      As of the Effective Date and on an ongoing basis, Label/Artist represents,
                      warrants and covenants that, (i) the use and exploitation of Voice Artist’s
                      voice in and in connection with the Track, and the use of the Track Artist
                      Credit (and, solely to the extent incorporated in the Track Artist Credit,
                      Voice Artist’s name) in connection with the Track, the use and exploitation
                      thereof, and the promotion and marketing of the Track, the Platform and the
                      DSPs, in each case as expressly set forth in this Agreement, will not violate
                      any law or infringe or violate any proprietary, intellectual property,
                      contract or other rights of any other person or entity, including without
                      limitation copyrights, trademark rights, performer’s rights, rights of
                      publicity and privacy, and any rights of exclusivity; (ii) Label/Artist has
                      full authority to act, in accordance with this Agreement, on behalf of Voice
                      Artist, and any and all other persons and entities owning or controlling any
                      right, title or interest in and to Voice Artist’s name and/or voice and/or the
                      Track Artist Credit; (iii) Label/Artist owns or controls all rights necessary
                      to make the grants of rights and licenses herein, and the exercise of such
                      rights and licenses by or on behalf of Hooky, Creator and their affiliates and
                      contractors will not violate or infringe the rights of any third party; (iv)
                      Label/Artist will be solely responsible for the payment of royalties and any
                      other amounts to Voice Artist and any other third parties who are entitled to
                      a royalty or other payment in connection with the permitted exploitation of
                      Voice Artist’s name and/or voice and/or the Track Artist Credit hereunder; and
                      (v) without limiting the generality of the foregoing, Label/Artist has
                      obtained, and will maintain on an ongoing basis, all rights, licenses,
                      permissions, clearances, waivers and/or approvals necessary from each person
                      or entity (including without limitation Voice Artist) that owns or controls
                      any interest in and to Voice Artist’s name and/or voice and/or the Track
                      Artist Credit necessary to make the uses permitted under this Agreement, free
                      of all claims and demands whatsoever, known or unknown, in law or equity.
                    </p>
                  </li>
                </ol>
              </li>

              <li>
                <p>
                  <u>Indemnification:</u>
                </p>

                <ul>
                  <li>
                    <p>
                      <u>By Creator.</u>
                    </p>

                    <p>
                      Creator agrees to defend, indemnify and hold harmless Hooky, Label/Artist, and
                      each of their subsidiaries, affiliates, successors, licensees, agents,
                      contractors (including Contractors), attorneys and assigns, and the officers,
                      directors, shareholders, contractors, members and employees of the foregoing,
                      from and against any and all liabilities, damages, awards, settlements,
                      losses, claims, suits, proceedings, assertions and expenses, including without
                      limitation court costs and reasonable third-party legal fees (collectively, “
                      <u>Losses</u>”), in connection with any claim, suit, proceeding, or assertion
                      by a third party: (i) based on allegations that, if assumed true, would
                      constitute a breach by Creator of this Agreement, including any warranty,
                      representation, agreement or covenant made in this Agreement by Creator; or
                      (ii) arising from or related to the rights and authorizations granted to Hooky
                      and/or Label/Artist in this Agreement or the exercise thereof.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>By Hooky.</u>
                    </p>

                    <p>
                      Hooky agrees to defend, indemnify and hold harmless Creator, Label/Artist, and
                      each of their subsidiaries, affiliates, successors, licensees, agents,
                      attorneys and assigns, and the officers, directors, shareholders, contractors,
                      members and employees of the foregoing, from and against any and all Losses in
                      connection with any claim, suit, proceeding, or assertion by a third party
                      based on allegations that, if assumed true, would constitute a breach by Hooky
                      of this Agreement, including any warranty, representation, agreement or
                      covenant made in this Agreement by Hooky.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>By Label/Artist.</u>
                    </p>

                    <p>
                      Label/Artist agrees to defend, indemnify and hold harmless Hooky, Creator, and
                      each of their subsidiaries, affiliates, successors, licensees, agents,
                      attorneys and assigns, and the officers, directors, shareholders, contractors,
                      members and employees of the foregoing, from and against any and all Losses in
                      connection with any claim, suit, proceeding, or assertion by a third party
                      based on allegations that, if assumed true, would constitute a breach by
                      Label/Artist of this Agreement, including any warranty, representation,
                      agreement or covenant made in this Agreement by Label/Artist.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Indemnification Procedure.</u>
                    </p>

                    <p>
                      The persons and entities entitled to be indemnified under sections 8(a), 8(b)
                      and 8(c) above (individually and collectively, “<u>Indemnitee</u>”) will: (i)
                      promptly inform the indemnifying party under such sections (“<u>Indemnitor</u>
                      ”) of each claim, suit or proceeding with respect to which Indemnitee seeks
                      indemnity, (ii) furnish to Indemnitor a copy of each communication, notice or
                      other action related to such claim, suit or proceeding, and (iii) give
                      Indemnitor the authority, information and reasonable assistance necessary to
                      settle or litigate such claim, suit or proceeding, using counsel selected by
                      Indemnitor (provided, however, that Indemnitee will have the opportunity to
                      participate in the defense of such suit or proceeding with counsel of its
                      choice, at Indemnitee’s sole cost). Any settlement of any such claim, suit or
                      proceeding by Indemnitor that imposes any requirements on Indemnitee, or which
                      involves agreements other than the payment of money by Indemnitor and receipt
                      of a full release for the benefit of Indemnitor and Indemnitee, will be
                      subject to Indemnitee’s written consent.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Offset:</u>
                    </p>

                    <p>
                      Without waiving any right or remedy available to Hooky and notwithstanding
                      anything to the contrary, if any claim is made for which Creator is obligated
                      to indemnify Hooky, its subsidiaries, affiliates, successors, licensees,
                      agents, contractors (including Contractors), attorneys and assigns, and/or the
                      officers, directors, shareholders, contractors, members and employees of the
                      foregoing, Hooky will have the right to withhold amounts otherwise payable to
                      Creator under this Agreement in an amount reasonably related to such claim and
                      to deduct therefrom any payments required to be made under this Agreement.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Limitation of Liability.</u>
                    </p>

                    <p>
                      WITHOUT LIMITING THE INDEMNITY OBLIGATIONS OF THE PARTIES PURSUANT TO THE
                      FOREGOING PROVISIONS OF THIS SECTION 8 AND OTHER THAN AS A RESULT OF A BREACH
                      OF THE CONFIDENTIALITY OBLIGATIONS HEREUNDER, NO PARTY HERETO WILL BE LIABLE
                      TO ANY OTHER PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR
                      SPECIAL DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT, INCLUDING DAMAGES
                      FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS
                      INFORMATION, AND THE LIKE, EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE
                      POSSIBILITY OF SUCH DAMAGES. HOOKY MAKES NO WARRANTY OR REPRESENTATION,
                      EXPRESS OR IMPLIED, TO CREATOR OR LABEL/ARTIST AS TO THE QUALITY, PERFORMANCE,
                      MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE PLATFORM OR ANY
                      ELEMENTS THEREOF.
                    </p>
                  </li>
                </ul>
              </li>

              <li>
                <p>
                  <u>Notices:</u>
                </p>

                <p>
                  All notices under this Agreement must be in writing in order to be effective, and
                  will be deemed to have been duly given or made (a) on the date delivered in
                  person, (b) on the date indicated on the return receipt if mailed postage prepaid,
                  by certified or registered U.S. Mail, with return receipt requested, or (c) if
                  sent by Federal Express, U.P.S. Next Day Air or other nationally recognized
                  overnight courier service, with service charges or postage prepaid, on the next
                  business day after delivery to the courier service (if sent in time for and
                  specifying next day delivery). Each notice to Hooky will be sent to Hooky at the
                  address first mentioned above, unless otherwise indicated in a notice duly given
                  hereunder, addressed to the attention of
                  <b>Jordan Young</b>, with required simultaneous copies to{' '}
                  <a href="mailto:support@hooky.co">support@hooky.co</a>; Greenberg Traurig, LLP,
                  3333 Piedmont Road NE, Suite 2500, Atlanta, GA 30305, Attn: Bobby Rosenbloum,
                  Esq.; and rosenbloumb@gtlaw.com. Each notice to Creator will be sent to Creator at
                  the address first mentioned above, unless otherwise indicated in a notice duly
                  given hereunder.
                </p>
              </li>

              <li>
                <p>
                  <u>Confidentiality:</u>
                </p>

                <p>
                  "<u>Confidential Information</u>" means the terms (but not the existence) of this
                  Agreement and any non-public information, data, reports, or other materials
                  provided by one party to the other under or in connection with this Agreement
                  (other than the Track, Creator Content and other information intended for public
                  display or distribution under this Agreement) and any other information the
                  receiving party should reasonably have understood under the circumstances should
                  be treated as confidential, whether or not the specific designation "confidential"
                  or any similar designation is used, such as royalty statements and similar
                  information. Except with the prior written consent of the disclosing party,
                  neither party will use or disclose any Confidential Information other than (i) to
                  such party’s attorneys, accountants and financial representatives under a duty of
                  confidentiality as may be reasonably necessary in order to receive their
                  professional advice, (ii) to such party’s employees and contractors who have a
                  need to know (and any disclosure to contractors may only be to contractors who are
                  bound by an agreement to protect the confidential information of third parties),
                  (iii) in connection with any legal, governmental or administrative proceeding,
                  provided that prior written notice of such disclosure is furnished to the
                  non-disclosing party in order to afford such non-disclosing party a reasonable
                  opportunity to seek a protective order (it being agreed that if the non-disclosing
                  party is unable to obtain or does not seek a protective order, disclosure of such
                  information in such proceeding may be made without liability), (iv) in the
                  ordinary course of such party’s fulfillment of its obligations hereunder solely to
                  the limited extent necessary to fulfill its written and/or legal obligations to
                  third parties, (v) by Hooky as may be required in connection with any reporting or
                  similar obligations it may have to Label/Artist, DSPs or other rightsholders, and
                  (vi) by Hooky to investors, prospective investors, acquirers and prospective
                  acquirers. In addition, nothing in this Agreement will prohibit or limit either
                  party's use or disclosure of information (a) previously known to it by lawful
                  means without obligation of confidence, (b) independently developed by or for it
                  without use of or access to the other party's Confidential Information, (c)
                  acquired by it from a third party which, to the reasonable knowledge of the
                  receiving party, is not under an obligation of confidence with respect to such
                  information, (d) which is or becomes publicly available through no breach of this
                  Agreement or (e) that is required to be disclosed by operation of law, court order
                  or other governmental demand (subject to the notice requirement in subsection
                  (iii) above).
                </p>
              </li>

              <li>
                <p>
                  <u>Miscellaneous:</u>
                </p>

                <ul>
                  <li>
                    <p>
                      <u>Entire Agreement.</u>
                    </p>

                    <p>
                      As between Label/Artist and Creator, this Agreement supersedes any and all
                      prior or contemporaneous agreements, either oral or written, between the
                      parties, and contains all of the covenants and agreements between the parties
                      with respect to the subject matter hereof. As between Hooky and Creator, this
                      Agreement supersedes any and all prior or contemporaneous agreements, either
                      oral or written, between the parties (other than Hooky’s Terms of Service),
                      and contains all of the covenants and agreements between the parties with
                      respect to the subject matter hereof. The parties acknowledge and agree that
                      no party has made any representations or promises in connection with this
                      Agreement or the subject matter hereof not contained herein.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Waiver & Severability.</u>
                    </p>

                    <p>
                      No waiver of any provision hereof or default of any promise hereunder shall
                      affect any party’s right thereafter to enforce such provision or to exercise
                      any right or remedy in the event of any subsequent default, whether or not
                      similar. If any part of this Agreement is held to be invalid or unenforceable,
                      the remaining provisions hereof shall nevertheless continue to be valid and
                      enforceable as though such invalid or unenforceable parts had not been
                      included herein.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Choice of Law / Venue.</u>
                    </p>

                    <p>
                      THIS AGREEMENT HAS BEEN ENTERED INTO IN THE STATE OF CALIFORNIA, AND THE
                      VALIDITY, INTERPRETATION AND LEGAL EFFECT OF THIS AGREEMENT WILL BE GOVERNED
                      BY THE LAW OF THE STATE OF CALIFORNIA APPLICABLE TO CONTRACTS ENTERED INTO AND
                      PERFORMED ENTIRELY THEREIN (NOTWITHSTANDING ANY CONFLICT OF LAW PRINCIPLES TO
                      THE CONTRARY). THE CALIFORNIA COURTS (STATE AND FEDERAL) LOCATED IN LOS
                      ANGELES, CALIFORNIA WILL HAVE SOLE JURISDICTION OF ANY CONTROVERSIES REGARDING
                      THIS AGREEMENT. THE PARTIES WAIVE ANY AND ALL OBJECTIONS TO VENUE IN THOSE
                      COURTS AND HEREBY SUBMIT TO THE JURISDICTION OF THOSE COURTS.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Assignment.</u>
                    </p>

                    <p>
                      This Agreement will be binding upon and inure to the benefit of the parties
                      and their permitted successors and assigns. No party may assign this Agreement
                      or any of such party’s rights or obligations hereunder without the other
                      party’s prior written consent, not to be unreasonably conditioned, withheld or
                      delayed, except that (i) for avoidance of doubt, any party may feely assign
                      any or all of such party’s ownership rights in the Track and/or its rights to
                      receive royalties in connection with the Track, and (ii) Hooky may assign this
                      Agreement and its rights and obligations hereunder in whole or in part to any
                      third party acquiring all or a substantial part of Hooky’s business
                      operations, provided that any such assignee shall be subject to all of Hooky’s
                      obligations under this Agreement. Any purported assignment in violation of the
                      foregoing will be deemed null and void ab initio and without force or effect.
                      Other than permitted assignees, no person or entity not a party to this
                      Agreement will have any rights or remedies under this Agreement, whether as a
                      third-party beneficiary or otherwise.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Relationship of the Parties.</u>
                    </p>

                    <p>
                      The parties have and will have the status of independent contractors, and this
                      Agreement creates no joint venture, partnership, agency or fiduciary
                      relationship existing between any of the parties, and the parties do not
                      intend to create any such relationship by this Agreement.
                    </p>
                  </li>

                  <li>
                    <p>
                      <u>Counterparts.</u>
                    </p>

                    <p>
                      This Agreement may be executed in one or more counterparts, each of which,
                      when taken together, will be deemed to constitute one and the same instrument.
                      Signatures transmitted by way of electronic mail or other electronic means
                      (e.g., PDF) will be deemed originals for all purposes.
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </ScrollArea.Autosize>
      </div>
    </Modal>
  );
};
