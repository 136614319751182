import { useEffect, useState } from 'react';
import { useMantineTheme } from '@mantine/core';

import { useModalStore, useSongStore } from '@/store';
import {
  useGetAccount,
  useGetParticipants,
  useGetSongTracks,
  useGetVoice,
  useSongPermission
} from '@/hooks';
import { Collaborator, ParticipantType } from '@/models/Participant.ts';
import AddIcon from '@/assets/icons/Add.tsx';
import { IconButton, PieChart, Table, Tooltip } from '@/components';
import { ADD_COLLABORATOR_MODAL_NAME, WORK_TYPE_SONGS } from '@/constants/song';
import { CollaborationsSongProps } from '@/models/Song.ts';

import { getUserMaxAvailableSplitPercent } from '@/components/organisms/Song/components/splits/components/utils/getUserMaxAvailableSplitPercent.ts';
import { COLUMNS } from './columns.tsx';
import { AddCollaboratorModal } from '../common';
import { RELEASE_MODAL_NAME } from '@/pages/Projects';

export const MasterTab = () => {
  const { hasReadPermission } = useSongPermission();
  const { openModal, modalOption } = useModalStore();
  const [displayedCollaborators, setDisplayedCollaborators] = useState<Collaborator[]>([]);
  const { songData, isSongEditable } = useSongStore();
  const { data: collaborators, isFetching } = useGetParticipants({
    workType: WORK_TYPE_SONGS,
    workId: (songData as CollaborationsSongProps).song_id || songData.id,
    type: ParticipantType.COLLABORATOR
  });
  const { data: tracks } = useGetSongTracks({
    songId: (songData as CollaborationsSongProps).song_id || songData.id
  });
  const voiceId = tracks?.[0]?.voice.id;
  const { data: voice } = useGetVoice({ voiceId });
  const { data: user } = useGetAccount();
  const { colors } = useMantineTheme();

  const userMaxAvailableSplitPercent = getUserMaxAvailableSplitPercent(
    collaborators,
    voice?.royalty_percent
  );

  useEffect(() => {
    const displayedCollaborators: Collaborator[] = [];
    if (voice) {
      const voiceRow: Collaborator = {
        type: ParticipantType.COLLABORATOR,
        id: voice.id,
        avatar_url: voice.avatar_url,
        name: voice.name,
        role: 'artistAi',
        locked: true,
        split: voice.royalty_percent ?? 0,
        status: 'approved'
      };
      displayedCollaborators.push(voiceRow);
    }
    if (user) {
      const creatorRow: Collaborator = {
        type: ParticipantType.COLLABORATOR,
        id: user.id,
        avatar_url: user.avatar,
        name: user.display_name,
        role: 'creator',
        locked: true,
        split: userMaxAvailableSplitPercent,
        status: 'approved'
      };
      displayedCollaborators.push(creatorRow);
    }
    if (collaborators) {
      displayedCollaborators.push(...(collaborators as Collaborator[]));
    }
    setDisplayedCollaborators(displayedCollaborators);
  }, [collaborators, user, userMaxAvailableSplitPercent, voice]);

  const chartData = displayedCollaborators.map(({ id, name, split }) => ({
    id,
    name,
    value: split
  }));

  const openCollaboratorModal = () =>
    openModal({
      name: ADD_COLLABORATOR_MODAL_NAME,
      userMaxAvailableSplitPercent
    });

  const isVisibleAddCollaborator =
    songData.status !== 'release_scheduled' || modalOption.name !== RELEASE_MODAL_NAME;

  return (
    <div className="flex flex-col pt-8">
      <PieChart data={chartData} label="Contributor • Master" />
      <div className="flex justify-between items-center mb-2">
        <div className="flex gap-x-1 text-sm font-semibold text-hookybase-500 dark:text-white w-full">
          COLLABORATORS
          <Tooltip tooltipContent="Adding a collaborator will allow you to input further split percentages" />
        </div>

        {isVisibleAddCollaborator && (
          <IconButton
            onClick={openCollaboratorModal}
            disabled={hasReadPermission || !isSongEditable}
          >
            <AddIcon color={isSongEditable ? colors.dark[0] : colors.dark[4]} />
          </IconButton>
        )}
      </div>
      <Table
        isLoading={isFetching && !displayedCollaborators}
        columns={COLUMNS}
        data={displayedCollaborators}
      />

      <AddCollaboratorModal songId={songData.id} />
    </div>
  );
};
