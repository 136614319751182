import { Link } from 'react-router-dom';

import { TODAY } from '@/constants';
import { Logo } from '@/components';
import { ROUTES } from '@/routes/routes';
import { HELP_CENTER_URL } from '@/config';

import styles from './footer.module.css';

const LINKS = [
  {
    title: 'Privacy Policy',
    link: ROUTES.PRIVACY_POLICY
  },
  {
    title: 'Terms of Use',
    link: ROUTES.TERMS_OF_USE
  },
  {
    title: 'Cookies Policy',
    link: ROUTES.COOKIE_POLICY
  },
  {
    title: 'Help',
    link: HELP_CENTER_URL
  }
];

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <span className={styles.copyright}>
          <Logo className={styles.footerLogo} />

          <span>© {TODAY.getFullYear()} Hooky AI</span>
        </span>

        <div className={styles.serviceLinks}>
          {LINKS.map((link) => (
            <Link key={link.title} to={link.link}>
              {link.title}
            </Link>
          ))}
        </div>

        <a href="#">United States</a>
      </div>
    </footer>
  );
};
