import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Modal as ModalCore, useMantineTheme } from '@mantine/core';
import { useModalStore, useSongStore } from '@/store';
import {
  useGetAccount,
  useGetParticipants,
  useGetSongTracks,
  useGetVoice,
  usePostParticipant,
  useSongPermission
} from '@/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { ParticipantType, PersonnelParticipant } from '@/models/Participant.ts';
import { RELEASE_MODAL_NAME } from '@/pages/Projects';
import { setServerFormError } from '@/utils/serverTestValidation.ts';
import AddIcon from '@/assets/icons/Add.tsx';
import { ROLE_OPTIONS_DROPDOWN_LIST, WORK_TYPE_SONGS } from '@/constants/song';
import {
  ButtonLib,
  IconButton,
  InputField,
  Modal,
  SelectField,
  Table,
  Tooltip
} from '@/components';

import { COLUMNS } from './columns';
import styles from './components.module.css';
import { CollaborationsSongProps } from '@/models/Song.ts';

interface FormValues {
  name: string;
  role: string;
}

const SCHEMA = Yup.object().shape({
  name: Yup.string().required('Name is required').min(3, 'Name must be at least 3 characters'),
  role: Yup.string().required('Role is required')
});

const ADD_PARTICIPANT_MODAL_NAME = 'addParticipantModal';

export const Credits = () => {
  const { closeModal, openModal, modalOption } = useModalStore();
  const { hasReadPermission } = useSongPermission();
  const [displayedPartisipants, setDisplayedPartisipants] = useState<PersonnelParticipant[]>([]);
  const { songData, isSongEditable } = useSongStore();
  const { data: participants, isFetching } = useGetParticipants({
    workType: WORK_TYPE_SONGS,
    workId: (songData as CollaborationsSongProps).song_id || songData.id
  });
  const {
    mutate: addParticipant,
    isLoading: isAddNewParticipantLoading,
    isSuccess: isAddNewParticipantSuccess,
    error: addNewParticipantError
  } = usePostParticipant({
    workType: WORK_TYPE_SONGS,
    workId: songData.id
  });
  const { handleSubmit, control, reset, formState, setError } = useForm<FormValues>({
    resolver: yupResolver(SCHEMA)
  });
  const { data: tracks } = useGetSongTracks({
    songId: (songData as CollaborationsSongProps).song_id || songData.id
  });
  const voiceId = tracks?.[0]?.voice.id;
  const { data: voice } = useGetVoice({ voiceId });
  const { data: user } = useGetAccount();
  const { colors } = useMantineTheme();

  useEffect(() => {
    const displayedParticipants: PersonnelParticipant[] = [];
    if (voice) {
      const voiceRow: PersonnelParticipant = {
        id: voice.id,
        avatar_url: voice.avatar_url,
        name: voice.name,
        role: 'artistAi',
        locked: true,
        type: ParticipantType.PARTICIPANT
      };
      displayedParticipants.push(voiceRow);
    }
    if (user) {
      const creatorRow: PersonnelParticipant = {
        id: user.id,
        avatar_url: user.avatar,
        name: user.display_name,
        role: 'creator',
        locked: true,
        type: ParticipantType.PARTICIPANT
      };
      displayedParticipants.push(creatorRow);
    }
    if (participants) {
      const participantsFiltered = participants.filter(
        (participant): participant is PersonnelParticipant =>
          participant.type === ParticipantType.PARTICIPANT
      );
      displayedParticipants.push(...participantsFiltered);
    }
    setDisplayedPartisipants(displayedParticipants);
  }, [participants, user, voice]);

  useEffect(() => {
    if (isAddNewParticipantSuccess) {
      closeModal();
      reset({ name: '', role: '' });
    }
  }, [closeModal, isAddNewParticipantSuccess, reset]);

  useEffect(() => {
    if (addNewParticipantError) {
      setServerFormError(addNewParticipantError, setError);
    }
  }, [addNewParticipantError, setError]);

  const isAddParticipantDisabled =
    hasReadPermission ||
    !isSongEditable ||
    (songData.status === 'release_song' && modalOption.name === RELEASE_MODAL_NAME);

  const openParticipantModal = () => {
    if (!isAddParticipantDisabled) {
      openModal({ name: ADD_PARTICIPANT_MODAL_NAME });
    }
  };

  const handleUpdateProfile = handleSubmit((values: FormValues) => {
    const valuesWithType = {
      ...values,
      type: ParticipantType.PARTICIPANT
    };
    addParticipant(valuesWithType);
  });

  const onCancelModal = () => {
    reset({ name: '', role: '' });
    closeModal();
  };

  return (
    <div className="flex flex-col pt-8">
      <div className="flex justify-between items-center mb-2">
        <div className="flex gap-x-1 text-sm font-semibold text-hookybase-500 dark:text-white w-full">
          PERSONNEL
          <Tooltip tooltipContent="Adding personnel here will not include them in the splits for your song" />
        </div>

        <IconButton onClick={openParticipantModal} disabled={isAddParticipantDisabled}>
          <AddIcon color={isSongEditable ? colors.dark[0] : colors.dark[4]} />
        </IconButton>
      </div>

      <Table
        isLoading={isFetching && !displayedPartisipants}
        columns={COLUMNS}
        data={displayedPartisipants}
      />

      <Modal
        name={ADD_PARTICIPANT_MODAL_NAME}
        onClose={closeModal}
        isCentered
        footer={
          <div className={styles.buttonsWrapper}>
            <ButtonLib
              background="secondary"
              variant="outline"
              onClick={onCancelModal}
              className={styles.button}
            >
              Cancel
            </ButtonLib>

            <ButtonLib
              type="submit"
              onClick={handleUpdateProfile}
              loading={isAddNewParticipantLoading}
              disabled={!formState.isDirty}
              className={styles.button}
            >
              Add
            </ButtonLib>
          </div>
        }
        head={
          <>
            <h2 className={styles.modalTitle}>ADD PERSONNEL</h2>
            <ModalCore.CloseButton className={styles.closeBtn} />
          </>
        }
      >
        <form className={styles.form}>
          <SelectField
            name="role"
            label="Role"
            placeholder="Select Role"
            control={control}
            options={ROLE_OPTIONS_DROPDOWN_LIST}
            description="*required"
          />
          <div className={styles.fieldWrapper}>
            <InputField
              name="name"
              label="Name"
              placeholder="Enter Name"
              control={control}
              description="*required"
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};
