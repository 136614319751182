import React from 'react';

const ChevronRightIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.7998 16L10.6665 9.86667L12.5332 8L20.5332 16L12.5332 24L10.6665 22.1333L16.7998 16Z"
        fill="currentColor"
      />
    </svg>
  );
};

ChevronRightIcon.displayName = 'ChevronRightIcon';

export default ChevronRightIcon;
