import React from 'react';

const ApprovalIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.7333 22.0974L22.6 20.2786L17.6667 15.4718V9.49573H15V16.5111L20.7333 22.0974ZM16.3333 28.9829C14.4889 28.9829 12.7556 28.6419 11.1333 27.9598C9.51111 27.2778 8.1 26.3521 6.9 25.1829C5.7 24.0137 4.75 22.6387 4.05 21.0581C3.35 19.4775 3 17.7886 3 15.9915C3 14.1943 3.35 12.5054 4.05 10.9248C4.75 9.34416 5.7 7.96923 6.9 6.8C8.1 5.63077 9.51111 4.70513 11.1333 4.02308C12.7556 3.34103 14.4889 3 16.3333 3C18.1778 3 19.9111 3.34103 21.5333 4.02308C23.1556 4.70513 24.5667 5.63077 25.7667 6.8C26.9667 7.96923 27.9167 9.34416 28.6167 10.9248C29.3167 12.5054 29.6667 14.1943 29.6667 15.9915C29.6667 17.7886 29.3167 19.4775 28.6167 21.0581C27.9167 22.6387 26.9667 24.0137 25.7667 25.1829C24.5667 26.3521 23.1556 27.2778 21.5333 27.9598C19.9111 28.6419 18.1778 28.9829 16.3333 28.9829Z"
        fill="currentColor"
      />
    </svg>
  );
};

ApprovalIcon.displayName = 'ApprovalIcon';

export default ApprovalIcon;
