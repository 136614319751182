import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '@/hooks';
import { ArtistSettingsProps } from '@/models/Account';
import { APPROVAL_STATUS } from '@/pages/Approvals/constants.ts';
import { notifications } from '@/utils/notifications';

export const usePutArtistSettings = () => {
  const api = useApi({});
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (form: ArtistSettingsProps): Promise<void> => {
      const result = await api.put('/v1/account/approvals', form);

      return result.data;
    },
    onSuccess: (_, params) => {
      queryClient.removeQueries({
        queryKey: [
          'approvals',
          APPROVAL_STATUS.pending,
          APPROVAL_STATUS.approved,
          APPROVAL_STATUS.denied,
          APPROVAL_STATUS.rejected
        ],
        exact: true
      });

      queryClient.setQueryData(['account-artist-settings'], () => params);

      notifications.success('User Profile is updated successfully');
    },
    onError: () => {
      notifications.error('Could not update User Profile');
    }
  });
};
