import React from 'react';
import { Switch as SwitchMantine } from '@mantine/core';
import { Control, useController } from 'react-hook-form';

import styles from './switch.module.css';

export interface SwitchProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  onChange?: (name: string, value: boolean) => void;
  label?: string;
  leftBox?: React.ReactNode;
  rightBox?: React.ReactNode;
  hint?: string;
  disabled?: boolean;
  standalone?: boolean;
}

const CLASSES = {
  track: `${styles.track} border-hookybase-500 dark:border-white`,
  thumb: styles.thumb,
  trackLabel: styles.trackLabel,
  input: styles.input
};

export const Switch: React.FC<SwitchProps> = (props) => {
  const { name, control, onChange, label, leftBox, rightBox, hint, disabled, standalone } = props;

  const { field } = useController({
    name,
    control,
    defaultValue: ''
  });
  const value = field.value;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(name, event.target.checked);
      if (standalone) {
        field.onChange(event.target.checked);
      }
      return;
    }

    field.onChange(event.target.checked);
  };

  // TODO update with form logic and color mode
  return (
    <div>
      {label ? (
        <label className="block text-sm font-semibold text-hookybase-500 dark:text-white w-full mb-2">
          {label}
        </label>
      ) : null}

      <div className={styles.switchBox}>
        <div className={`${!value ? styles.active : ''}`}>{leftBox}</div>

        <SwitchMantine
          {...field}
          onChange={handleChange}
          checked={value}
          disabled={disabled}
          classNames={CLASSES}
        />

        <div className={`${value ? styles.active : ''}`}>{rightBox}</div>
      </div>

      <div className="text-sm text-hookybase-200">{hint}</div>
    </div>
  );
};
