import React, { ReactNode } from 'react';
import { ActionIcon } from '@mantine/core';

interface IconButtonProps {
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  size?: string;
}
export const IconButton = (props: IconButtonProps) => {
  const { children, ...rest } = props;

  return <ActionIcon {...rest}>{children}</ActionIcon>;
};
