import React from 'react';

const AmexIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="44"
      height="28"
      viewBox="0 0 44 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.462646" width="43.4627" height="28" rx="5.01493" fill="#006FCF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9419 5.07006H40.3444L38.4641 10.4662L36.5018 5.07006H32.8227V13.4723L29.0617 5.07006H26.0367L22.2758 13.6547H24.8919L25.6278 11.9379H29.5523L30.2881 13.6547H35.1937V7.68626L37.4011 13.6547H39.4451L41.6525 7.68626V13.6547H43.9419V14.9628H40.9167L38.4641 17.6609L35.9295 14.9628H25.5461V23.5475H35.8479L38.3822 20.7678L40.9167 23.5475H43.9419V24.6102H40.3444L38.3822 22.4846L36.4199 24.6102H24.238V14.7993H20.3135L25.219 3.76172H29.961L31.6779 7.52272V3.76172H37.5647L38.5457 6.62335L39.527 3.76172H43.9419V5.07006ZM43.9419 16.8432L41.7344 19.2142L43.9419 21.5852V23.5475L39.9357 19.2142L43.9419 14.9628V16.8432ZM32.8227 23.5475V21.5036H27.9171V20.1955H32.7408V18.2332H27.9171V16.9251H32.8227V14.9628L36.9105 19.2142L32.8227 23.5475ZM26.3636 10.0573L27.5084 7.27752L28.6529 10.0573H26.3636Z"
        fill="white"
      />
    </svg>
  );
};

AmexIcon.displayName = 'AmexIcon';

export default AmexIcon;
