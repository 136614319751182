import React from 'react';

const SpinnerIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9998 29.3333C14.1776 29.3333 12.4554 28.9833 10.8332 28.2833C9.21095 27.5833 7.79428 26.6277 6.58317 25.4166C5.37206 24.2055 4.4165 22.7888 3.7165 21.1666C3.0165 19.5444 2.6665 17.8222 2.6665 16C2.6665 14.1555 3.0165 12.4277 3.7165 10.8166C4.4165 9.20552 5.37206 7.7944 6.58317 6.58329C7.79428 5.37218 9.21095 4.41663 10.8332 3.71663C12.4554 3.01663 14.1776 2.66663 15.9998 2.66663C16.3776 2.66663 16.6943 2.7944 16.9498 3.04996C17.2054 3.30551 17.3332 3.62218 17.3332 3.99996C17.3332 4.37774 17.2054 4.6944 16.9498 4.94996C16.6943 5.20551 16.3776 5.33329 15.9998 5.33329C13.0443 5.33329 10.5276 6.37218 8.44984 8.44996C6.37206 10.5277 5.33317 13.0444 5.33317 16C5.33317 18.9555 6.37206 21.4722 8.44984 23.55C10.5276 25.6277 13.0443 26.6666 15.9998 26.6666C18.9554 26.6666 21.4721 25.6277 23.5498 23.55C25.6276 21.4722 26.6665 18.9555 26.6665 16C26.6665 15.6222 26.7943 15.3055 27.0498 15.05C27.3054 14.7944 27.6221 14.6666 27.9998 14.6666C28.3776 14.6666 28.6943 14.7944 28.9498 15.05C29.2054 15.3055 29.3332 15.6222 29.3332 16C29.3332 17.8222 28.9832 19.5444 28.2832 21.1666C27.5832 22.7888 26.6276 24.2055 25.4165 25.4166C24.2054 26.6277 22.7943 27.5833 21.1832 28.2833C19.5721 28.9833 17.8443 29.3333 15.9998 29.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

SpinnerIcon.displayName = 'SpinnerIcon';

export default SpinnerIcon;
