import React from 'react';

const ArrowBackIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.4335 17.3334L17.9002 24.8L16.0002 26.6667L5.3335 16L16.0002 5.33337L17.9002 7.20004L10.4335 14.6667H26.6668V17.3334H10.4335Z"
        fill="currentColor"
      />
    </svg>
  );
};

ArrowBackIcon.displayName = 'ArrowBackIcon';

export default ArrowBackIcon;
