import { FC, MouseEvent, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import { useMediaQuery } from '@mantine/hooks';
import { useMantineTheme } from '@mantine/core';

import { ROUTES } from '@/routes/routes';
import { Icon, Logo } from '@/components';
import { HELP_CENTER_URL } from '@/config';
import { useGetAccount } from '@/hooks';
import { useNavStore } from '@/store';

interface NavItem {
  icon: string;
  label: string;
  url?: string;
  onClick?: () => void;
  new?: boolean;
  isExternal?: boolean;
  isArtistRestricted?: boolean;
}

interface NavProps {
  className?: string;
  footer?: ReactNode;
  onResize?: () => void;
  onNavigate?: (navItem: NavItem) => void;
}

const NAV_ITEMS: NavItem[] = [
  {
    icon: 'Home',
    label: 'Home',
    url: ROUTES.DASHBOARD
  },
  {
    icon: 'Create',
    label: 'Create',
    url: ROUTES.ARTISTS
  },
  {
    icon: 'Projects',
    label: 'Projects',
    url: ROUTES.PROJECTS
  },
  {
    icon: 'Approvals',
    label: 'Approvals',
    url: ROUTES.APPROVALS,
    isArtistRestricted: true
  },
  {
    icon: 'Help',
    label: 'Help',
    url: HELP_CENTER_URL,
    isExternal: true
  }
];

export const Nav: FC<NavProps> = ({ className = '', footer, onResize, onNavigate }) => {
  const navigate = useNavigate();
  const { isNavOpen, setIsNavOpen } = useNavStore();
  const theme = useMantineTheme();
  const md = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);
  const { data: user } = useGetAccount();

  useEffect(() => {
    if (onResize) onResize();
  }, [onResize, isNavOpen]);

  const handleMouseEnter = () => {
    setIsNavOpen(true);
  };

  const handleMouseLeave = () => {
    setIsNavOpen(false);
  };

  const handleNavigation = (navItem: NavItem) => (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    if (navItem.isExternal) return;
    if (navItem?.onClick) return navItem.onClick();
    if (navItem?.url) return navigate(navItem.url);
    if (onNavigate) return onNavigate(navItem);
    return;
  };

  const drawerClass = clsx({
    'absolute md:fixed top-[3.875rem] md:top-0 left-0 bottom-0 z-50 text-hookybase-100 dark:text-white bg-white dark:bg-black transition-all duration-75':
      true,
    'border-0 md:border-r-2 md:border-hookybase-50 md:dark:border-hookybase-500': true,
    'p-3 w-full md:w-[180px]': isNavOpen,
    'p-0 md:p-3 w-0 md:w-[80px]': !isNavOpen,
    [`${className}`]: true
  });

  const itemClass = clsx({
    'flex items-center gap-4 w-full rounded py-2.5 text-hookybase-300 [@media(hover:hover){&:hover}]:bg-hookybase-50 dark:[@media(hover:hover){&:hover}]:bg-hookybase-500 dark:[@media(hover:hover){&:hover}]:text-hookyyellow-500':
      true,
    'pl-4': isNavOpen,
    'justify-center': !isNavOpen
  });

  return (
    <div
      className={drawerClass}
      onTouchEnd={!md ? handleMouseLeave : undefined}
      onMouseEnter={md ? handleMouseEnter : undefined}
      onMouseLeave={handleMouseLeave}
    >
      <Logo className="h-12 mt-3 mb-6 text-hookybase-500" expand={isNavOpen} />
      <ul className={`flex flex-col ${isNavOpen ? 'open' : 'closed items-center'}`}>
        {NAV_ITEMS.map((item, index) =>
          !item.isArtistRestricted || user?.artist ? (
            <li key={index} onClick={handleNavigation(item)} className="w-full mb-1 cursor-pointer">
              {item.isExternal ? (
                <a href={item.url} className={itemClass} target="_blank" rel="noopener noreferrer">
                  <Icon icon={item.icon} size="3xl" />
                  {isNavOpen && <span className="uppercase text-sm">{item.label}</span>}
                </a>
              ) : (
                <div className={itemClass}>
                  <Icon icon={item.icon} size="3xl" />
                  {isNavOpen && <span className="uppercase text-sm">{item.label}</span>}
                </div>
              )}
            </li>
          ) : null
        )}
      </ul>
      {footer && <div className="footer">{footer}</div>}
    </div>
  );
};
