import React from 'react';

const ArrowRightCircleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.75">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM18.3333 16.3333L26 24L18.3333 31.6667L20.6667 34L30.6667 24L20.6667 14L18.3333 16.3333Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

ArrowRightCircleIcon.displayName = 'ArrowRightCircle';

export default ArrowRightCircleIcon;
