import React from 'react';

const AddIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4335 17.5667H5.8335V14.4334H14.4335V5.80005H17.5668V14.4334H26.2002V17.5667H17.5668V26.1667H14.4335V17.5667Z"
        fill="currentColor"
      />
    </svg>
  );
};

AddIcon.displayName = 'AddIcon';

export default AddIcon;
