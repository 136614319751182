import { FC } from 'react';
import { Control, useController } from 'react-hook-form';
import { NumberInput } from '@mantine/core';

import { Slider } from './Slider.tsx';
import styles from './slider-base.module.css';

interface SliderControlProps {
  name: string;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  max?: number;
  thumbAlwaysShown?: boolean;
  valueUnits?: string;
  description?: string;
  maxValue?: number;
  maxLength?: number;
  defaultValue?: number;
}

export const SliderField: FC<SliderControlProps> = ({
  control,
  name,
  label,
  max,
  thumbAlwaysShown,
  valueUnits,
  description,
  maxValue = 100,
  maxLength = 3,
  defaultValue = 0
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue
  });

  const handleChange = (value: number | string) => {
    if (maxValue && +value > maxValue) {
      field.onChange(maxValue);

      return;
    }

    field.onChange(value ? value : 0);
  };

  return (
    <div className={styles.wrapper}>
      <div className="flex justify-between items-center mb-2">
        <span className={styles.label}>{label}</span>

        {valueUnits ? (
          <span className={styles.inputBox}>
            <NumberInput
              className={styles.enterInput}
              onChange={handleChange}
              value={field.value}
              maxLength={maxLength}
              error={Boolean(fieldState.error)}
              allowNegative={false}
              allowLeadingZeros
              hideControls
              disabled={maxValue === 0}
            />

            <span>{valueUnits}</span>
          </span>
        ) : null}
      </div>

      <Slider
        {...field}
        disabled={maxValue === 0}
        max={max}
        maxValue={maxValue}
        thumbAlwaysShown={thumbAlwaysShown}
      />

      {description ? <p className="mt-2 text-xs text-hookybase-200">{description}</p> : null}
    </div>
  );
};
