import { FC, useEffect, useState } from 'react';
import { Icon, Typo } from '@/components';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { MENU_SETTINGS } from '@/pages/Account/menu.tsx';
import { useGetAccount } from '@/hooks';
import { ROUTES } from '@/routes/routes.tsx';

const linkTextClassName =
  'text-hookybase-300 dark:text-white hover:text-hookybase-600 dark:hover:text-white';

const liClassName = twMerge([
  'flex justify-between items-center p-2 mb-2',
  'rounded bg-hookybase-50 dark:bg-hookybase-500 text-hookybase-300 dark:text-hookybase-200 hover:text-hookybase-600 dark:hover:text-white',
  'hover:bg-hookybase-100 dark:hover:bg-hookybase-400 transition-colors duration-200'
]);

const AccountAsideSections: FC = () => {
  const [menu, setMenu] = useState(MENU_SETTINGS);
  const { data: user } = useGetAccount();

  useEffect(() => {
    if (user && user?.artist) {
      const newMenu = [...MENU_SETTINGS];

      newMenu.splice(1, 0, {
        key: 'artist',
        titleGroup: 'ARTIST SETTINGS',
        items: [
          {
            key: 'artist-approval',
            link: ROUTES.ACCOUNT_ARTIST_APPROVAL,
            title: 'Artist settings'
          }
        ]
      });

      setMenu(newMenu);
    }
  }, [user]);

  return menu.map((groupSetting) => (
    <section key={groupSetting.key} className="mb-9">
      <Typo.h3 className="mb-2">{groupSetting.titleGroup}</Typo.h3>
      <ul>
        {groupSetting.items.map((setting) => (
          <li key={setting.key}>
            <Link to={setting.link} className={liClassName}>
              <div className={linkTextClassName}>{setting.title}</div>
              <Icon icon="ChevronRight" size="3xl" />
            </Link>
          </li>
        ))}
      </ul>
    </section>
  ));
};
export default AccountAsideSections;
