import { ReactElement, ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export interface TabProps {
  className?: string;
  label: string;
  children: ReactNode;
  name?: string;
}

export interface TabsProps {
  className?: string;
  children: Array<ReactElement<TabProps | Element> | null>;
  variant?: 'pill' | 'text' | 'square';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  onTabClick?: (tabName: string) => void;
}

const Tab = ({ className = '', children, ...rest }: TabProps) => (
  <div className={className} {...rest}>
    {children}
  </div>
);

// TODO update style
const Tabs = ({
  className = '',
  children,
  variant = 'pill',
  size = 'sm',
  onTabClick
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  let classes = '';

  switch (variant) {
    case 'text':
      classes = 'rounded-none border-0 pr-6 ';
      break;
    case 'square':
      classes = 'rounded-md border px-6 ';
      break;
    case 'pill':
    default:
      classes = 'rounded-full border px-6';
      break;
  }

  switch (size) {
    case 'xs':
      classes += ' text-xs';
      break;
    case 'md':
      classes += ' text-md';
      break;
    case 'lg':
      classes += ' text-xl';
      break;
    case 'sm':
    default:
      classes += ' text-sm';
      break;
  }

  const activeClasses =
    variant === 'text'
      ? 'text-black dark:text-white'
      : 'bg-hookybase-100 text-black dark:bg-white text-hookybase-600 border-hookybase-100 dark:border-white';
  const inactiveClasses =
    variant === 'text'
      ? 'text-hookybase-200 border-hookybase-400 dark:text-hookybase-300 dark:hover:text-hookybase-100 focus:border-white dark:hover:border-white'
      : 'bg-hookybase-50 dark:bg-hookybase-400 dark:border-hookybase-400 text-hookybase-200 hover:text-hookybase-300 focus:border-white hover:border-hookybase-200 dark:hover:border-white';

  return (
    <div className={twMerge('w-full', className)}>
      <div className="flex flex-wrap gap-2">
        {children.map((child, index) => {
          if (!child?.props.children) {
            return null;
          }

          const handleClick = () => {
            setActiveTab(index);

            if (onTabClick) {
              onTabClick((child.props as TabProps).name || '');
            }
          };

          return (
            <button
              key={index}
              className={twMerge(
                'flex-0 whitespace-nowrap py-2 font-bold border uppercase focus:outline-none',
                classes,
                activeTab === index ? activeClasses : inactiveClasses
              )}
              onClick={handleClick}
            >
              {(child.props as TabProps).label}
            </button>
          );
        })}
      </div>

      <div className="mt-4 text-white w-full">{children[activeTab]}</div>
    </div>
  );
};

export { Tab, Tabs };
