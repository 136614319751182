import { useEffect } from 'react';
import { useModalStore, SubscriptionModalOptions } from '@/store';
import { usePutSubscription } from '@/hooks';
import { getPrice } from '@/utils/formatText';
import { ModalFooterBtn, Modal } from '@/components';

export const CONFIRM_SUBSCRIPTION_CHANGE_MODAL = 'CONFIRM_SUBSCRIPTION_CHANGE_MODAL';
export const SUCCESS_SUBSCRIPTION_CHANGE_MODAL = 'SUCCESS_SUBSCRIPTION_CHANGE_MODAL';

export const ConfirmSubscriptionModal = () => {
  const { modalOption, closeModal, openModal } = useModalStore();
  const { mutate: updateSubscription, status } = usePutSubscription();

  const data = modalOption.data as SubscriptionModalOptions;

  useEffect(() => {
    if (status === 'success') {
      openModal({ name: SUCCESS_SUBSCRIPTION_CHANGE_MODAL });
    }
  }, [status, openModal]);

  const handleConfirm = () =>
    updateSubscription({
      plan_id: data.selected.id
    });

  const footerBtns: ModalFooterBtn[] = [
    {
      key: 'cancel',
      background: 'secondary',
      variant: 'outline',
      onClick: closeModal,
      content: 'CANCEL'
    },
    {
      key: 'confirm',
      disabled: status === 'loading',
      loading: status === 'loading',
      onClick: handleConfirm,
      content: 'CONFIRM'
    }
  ];

  if (!data) {
    return;
  }

  return (
    <Modal
      name={CONFIRM_SUBSCRIPTION_CHANGE_MODAL}
      title="CONFIRM SUBSCRIPTION"
      footerBtns={footerBtns}
      isCentered
    >
      <p className="text-2xl mb-4">You are about to change your subscription.</p>
      <p className="underline underline-offset-2 text-xl">Current plan</p>
      <ul className="pl-8 mb-2">
        <li>Name: {data.current.name}</li>
        <li>Price: {getPrice(data.current.amount, data.current.interval)}</li>
      </ul>
      <p className="underline underline-offset-2 text-xl">New plan</p>
      <ul className="pl-8">
        <li>Name: {data.selected.name}</li>
        <li>Price: {getPrice(data.selected.amount, data.selected.interval)}</li>
      </ul>
      <p className="text-xl mt-4">Please confirm to proceed</p>
    </Modal>
  );
};
