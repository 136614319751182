import React from 'react';

const CloseIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.53317 25.3333L6.6665 23.4666L14.1332 16L6.6665 8.53329L8.53317 6.66663L15.9998 14.1333L23.4665 6.66663L25.3332 8.53329L17.8665 16L25.3332 23.4666L23.4665 25.3333L15.9998 17.8666L8.53317 25.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

CloseIcon.displayName = 'CloseIcon';

export default CloseIcon;
