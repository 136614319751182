import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { PlaySongCell } from '@/components';
import { SongTrackProps } from '@/models/Song';
import { ItemMenuCell } from './ItemMenuCell';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const columnHelper = createColumnHelper<SongTrackProps>();

const convertByteToMb = (bytes?: number) =>
  `${Number(Math.round(Number(bytes) / 10485.76) + 'e-2')}MB`;

// eslint-disable-next-line react-refresh/only-export-components
export const COLUMNS = [
  columnHelper.accessor('name', {
    header: 'TITLE',
    cell: (info) => {
      const { original } = info.row;

      const isLoading = original.status_percent < 100 && original.status !== 'complete';

      const data = {
        image_url: original.voice.avatar_url,
        title: original.name,
        subtitle: original.voice.name,
        master_url: original.output_url,
        id: original.id
      };

      return <PlaySongCell data={data} isLoading={isLoading} />;
    },
    size: 50
  }),
  columnHelper.accessor('created_at', {
    header: 'CREATED',
    cell: (info) => (
      <div data-content="cell-split" className="text-hookybase-200 dark:text-hookybase-300">
        {dayjs(info.getValue()).format(DEFAULT_DATE_FORMAT)}
      </div>
    ),
    size: 20
  }),
  columnHelper.accessor('file_size', {
    header: () => 'SIZE',
    cell: (info) => (
      <div data-content="cell-status" className="text-hookybase-200 dark:text-hookybase-300">
        {convertByteToMb(info.getValue())}
      </div>
    ),
    size: 15
  }),
  columnHelper.accessor('duration', {
    header: () => 'DURATION',
    cell: (info) => (
      <div data-content="cell-status" className="text-hookybase-200 dark:text-hookybase-300">
        {new Date(Number(info.getValue() ?? 0) * 1000).toISOString().substring(14, 19)}
      </div>
    ),
    size: 15
  }),
  columnHelper.accessor('id', {
    header: ' ',
    cell: ({
      row: {
        original: { id, output_url }
      }
    }) => <ItemMenuCell id={id} output_url={output_url} />,
    size: 1
  })
];
