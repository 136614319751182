import { useEffect, useState } from 'react';

export interface SongInfoProps {
  title: string;
  imageUrl: string;
  subtitle: string;
  audio: string;
  id: string;
}
export interface UseAudioProps {
  togglePlay: () => void;
  isPaused: boolean;
  isPlaying: boolean;
  file: string;
  setFile: (data: SongInfoProps) => void;
  toggleRepeat: () => void;
  isRepeating: boolean;
  volume: number;
  setVolume: (volume: number) => void;
  progress: number;
  setProgress: (progress: number) => void;
  duration: number;
  trackInfo?: SongInfoProps;
}

export const useAudio = (): UseAudioProps => {
  const [audio] = useState(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRepeating, setIsRepeating] = useState(false);
  const [progress, setProgressInternal] = useState(0);
  const [duration, setDuration] = useState(0);
  const [songInfo, setSongInfo] = useState<SongInfoProps>();

  const setFile = async (data: SongInfoProps) => {
    audio.pause();
    audio.src = `${data.audio}?noCache=${Math.floor(Date.now() / 1000)}`;
    audio.load();
    setProgress(0);
    setSongInfo(data);

    try {
      await audio.play();
      setIsPlaying(true);
    } catch (err) {
      setIsPlaying(false);
    }
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const toggleRepeat = () => {
    setIsRepeating(!isRepeating);
  };

  const setVolume = (volume: number) => {
    audio.volume = volume;
  };

  const setProgress = (progress: number) => {
    audio.currentTime = progress;
    setProgressInternal(progress);
  };

  useEffect(() => {
    const updateProgress = () => setProgressInternal(audio.currentTime);
    const setAudioDuration = () => setDuration(audio.duration);

    audio.addEventListener('timeupdate', updateProgress);
    audio.addEventListener('loadedmetadata', setAudioDuration);
    audio.addEventListener('ended', () => setIsPlaying(false));
    audio.loop = isRepeating;

    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
      audio.removeEventListener('loadedmetadata', setAudioDuration);
      audio.removeEventListener('ended', () => setIsPlaying(false));
    };
  }, [audio, isRepeating]);

  useEffect(() => {
    isPlaying ? audio.play() : audio.pause();
  }, [isPlaying, audio]);

  return {
    togglePlay,
    isPaused: !isPlaying,
    isPlaying,
    file: audio.src,
    setFile,
    toggleRepeat,
    isRepeating,
    volume: audio.volume,
    setVolume,
    progress,
    setProgress,
    duration,
    trackInfo: songInfo
  };
};
