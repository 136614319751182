import React from 'react';

const ProjectsIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.33366 29.3327C4.60033 29.3327 3.97255 29.0716 3.45033 28.5493C2.9281 28.0271 2.66699 27.3993 2.66699 26.666V13.3327C2.66699 12.5993 2.9281 11.9716 3.45033 11.4493C3.97255 10.9271 4.60033 10.666 5.33366 10.666H26.667C27.4003 10.666 28.0281 10.9271 28.5503 11.4493C29.0725 11.9716 29.3337 12.5993 29.3337 13.3327V26.666C29.3337 27.3993 29.0725 28.0271 28.5503 28.5493C28.0281 29.0716 27.4003 29.3327 26.667 29.3327H5.33366ZM13.3337 25.3327L21.3337 19.9993L13.3337 14.666V25.3327ZM5.33366 9.33268V6.66602H26.667V9.33268H5.33366ZM9.33366 5.33268V2.66602H22.667V5.33268H9.33366Z"
        fill="currentColor"
      />
    </svg>
  );
};

ProjectsIcon.displayName = 'ProjectsIcon';

export default ProjectsIcon;
