import React from 'react';

const SpotifyIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_84_1688)">
        <path
          d="M15.9994 0.0527344C7.19006 0.0527344 0.0483398 7.19426 0.0483398 16.0036C0.0483398 24.8133 7.19006 31.9543 15.9994 31.9543C24.8097 31.9543 31.9506 24.8133 31.9506 16.0036C31.9506 7.19483 24.8097 0.0534963 15.9992 0.0534963L15.9994 0.0527344ZM23.3144 23.0585C23.0287 23.527 22.4154 23.6756 21.9468 23.388C18.2017 21.1004 13.487 20.5823 7.93463 21.8508C7.39958 21.9727 6.86625 21.6375 6.74434 21.1023C6.62186 20.567 6.95577 20.0337 7.49215 19.9118C13.5683 18.5236 18.7803 19.1213 22.9849 21.6908C23.4535 21.9785 23.6021 22.5899 23.3144 23.0585ZM25.2668 18.7152C24.9068 19.3004 24.1411 19.4851 23.5563 19.1251C19.2687 16.4897 12.7329 15.7265 7.66148 17.2659C7.00377 17.4645 6.3091 17.0939 6.10948 16.4373C5.91139 15.7796 6.28225 15.0863 6.93882 14.8863C12.7318 13.1285 19.9335 13.98 24.8573 17.0057C25.4421 17.3657 25.6268 18.1312 25.2668 18.7152ZM25.4344 14.1925C20.2935 11.139 11.8116 10.8583 6.9032 12.348C6.11501 12.587 5.28148 12.1421 5.04263 11.3539C4.80377 10.5653 5.24834 9.73235 6.0371 9.49274C11.6716 7.78226 21.0382 8.11273 26.9571 11.6264C27.6676 12.0472 27.9 12.9628 27.479 13.6708C27.06 14.3798 26.1419 14.6135 25.4352 14.1925H25.4344Z"
          fill="#1ED760"
        />
      </g>
      <defs>
        <clipPath id="clip0_84_1688">
          <rect width="32" height="32" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

SpotifyIcon.displayName = 'SpotifyIcon';

export default SpotifyIcon;
