import { Typo } from '@/components';
import { ROUTES } from '@/routes/routes.tsx';

const platformRules = [
  'You have all necessary rights in the recordings and other materials that you upload to the platform. This includes strictly no racism and no hate speech.',
  'You represent that your use of this platform will not violate any of your agreements with any persons or entities. Voice artist may always have the opportunity to take down a song, so please respect them creatively.',
  'You must re-upload your finished song to Hooky to receive voice artist approval for a valid license. If your song is not approved by the voice artist, you are prohibited from making any use of the song with the AI voice of the artist and must delete any copies.  Any use of the song with the AI voice of the artist that is not approved by the artist is against the law.',
  'Hooky has exclusive distribution and monetization rights. You must release your song through Hooky distribution for monetization.',
  `You will be permitted to post voice artist-approved recordings to your social media accounts or YouTube if you follow certain <a class="underline" href=${ROUTES.TERMS_OF_USE} target="_blank">rules/requirements</a>. You will not be permitted to otherwise distribute, post or exploit the voice artist-approved recordings.`
];

export const PlatformRules = () => (
  <div className="mb-12 flex-1">
    <Typo.h3 className="mb-6">Platform Rules</Typo.h3>
    <ul className="text-sm">
      {platformRules.map((item, index) => (
        <li key={index} className="flex items-center mb-3">
          <span className="inline-block w-12 h-12 mr-6 rounded-full border border-hookybase-400 text-hookybase-500 dark:text-hookybase-300 flex justify-center items-center shrink-0 font-bold">
            {index + 1}
          </span>
          &nbsp;
          <span
            className="text-hookybase-500 dark:text-white"
            dangerouslySetInnerHTML={{ __html: item }}
          />
        </li>
      ))}
    </ul>
    <p className="mt-6 text-[10px] text-hookybase-300">
      PLEASE NOTE: The royalty split percentage is a consolidated percentage that represents the net
      royalty and copyright ownership
    </p>
  </div>
);
