import { AdvisoryType, SingleSongStatus } from '@/models/Song.ts';

export const STATUS: { [k in SingleSongStatus]: string } = {
  draft: 'DRAFT',
  denied: 'DENIED',
  upload_for_approval: 'UPLOAD FOR APPROVAL',
  awaiting_approval: 'AWAITING APPROVAL',
  change_artist: 'CHANGE ARTIST',
  released: 'RELEASED',
  release_song: 'RELEASE SONG',
  release_scheduled: 'RELEASE SCHEDULED',
  taken_down: 'TAKEN DOWN',
  archived: 'ARCHIVED'
};

export const COLLABORATOR_STATUS = {
  pending: 'pending',
  approved: 'approved'
};

export const UPLOAD_MASTER_MODAL_NAME = 'upload_master';
export const DELETE_SONG_MODAL_NAME = 'delete_song';
export const ADD_COLLABORATOR_MODAL_NAME = 'addCollaboratorModal';
export const AGREED_TERMS_UPLOAD_TRACK_MODAL_NAME = 'agreed_terms_upload_track';

export const WORK_TYPE_SONGS = 'songs';
const ROLE_OPTIONS = {
  featuredArtist: 'Featured Artist',
  producer: 'Producer',
  mixer: 'Mixer',
  remixer: 'Remixer',
  engineer: 'Engineer',
  masteringEngineer: 'Mastering Engineer',
  lyricist: 'Lyricist',
  publisher: 'Publisher',
  other: 'Other'
};

const HIDDEN_ROLE_OPTIONS = {
  artistAi: 'Artist AI',
  creator: 'Creator (You)'
};

export const ROLE_OPTIONS_DROPDOWN_LIST = Object.entries(ROLE_OPTIONS).map(([key, value]) => ({
  label: value,
  value: key
}));
export const ROLE_OPTIONS_AGGREGATED = { ...HIDDEN_ROLE_OPTIONS, ...ROLE_OPTIONS };

export const TAB_NAME = {
  vocalAI: 'vocalAI',
  vocalAIVocal: 'vocalAI.vocal',
  vocalAILicenses: 'vocalAI.licenses',
  songInfo: 'songInfo',
  songInfoGeneral: 'songInfo.general',
  songInfoCredits: 'songInfo.credits',
  songInfoRelease: 'songInfo.release',
  splits: 'splits',
  splitsMaster: 'splits.master',
  splitsCollaborations: 'splits.collaborations'
};

export const TITLE_VERSIONS = [
  {
    label: 'Original',
    value: 'original'
  },
  {
    label: 'Remix',
    value: 'remix'
  },
  {
    label: 'Live',
    value: 'live'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export const ADVISOR_OPTION: { [k in Lowercase<AdvisoryType>]: AdvisoryType } = {
  explicit: 'EXPLICIT',
  none: 'NONE',
  clean: 'CLEAN'
};
