import { useAudio, UseAudioProps } from '@/hooks';
import React, { createContext, FC, useMemo } from 'react';

const AudioContext = createContext<UseAudioProps | undefined>(undefined);

// eslint-disable-next-line react-refresh/only-export-components
export const useAudioContext = () => {
  const context = React.useContext(AudioContext);
  if (!context) {
    throw new Error('useAudioContext must be used within an AudioProvider');
  }
  return context;
};

export interface AudioProviderProps {
  children: React.ReactNode;
}

export const AudioProvider: FC<AudioProviderProps> = ({ children }) => {
  const audioProps = useAudio();

  const contextValue = useMemo(() => audioProps, [audioProps]);

  return <AudioContext.Provider value={contextValue}>{children}</AudioContext.Provider>;
};
