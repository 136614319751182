import React from 'react';

const DeleteIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 42C12.9 42 11.9583 41.6083 11.175 40.825C10.3917 40.0417 10 39.1 10 38V12H8V8H18V6H30V8H40V12H38V38C38 39.1 37.6083 40.0417 36.825 40.825C36.0417 41.6083 35.1 42 34 42H14ZM18 34H22V16H18V34ZM26 34H30V16H26V34Z"
        fill="currentColor"
      />
    </svg>
  );
};

DeleteIcon.displayName = 'DeleteIcon';

export default DeleteIcon;
