import { ChangeEvent, useRef } from 'react';
import { useModalStore } from '@/store';
import { ALLOWED_SONG_EXTS } from '@/constants';
import { Typo, Modal, ButtonLib, Icon } from '@/components';
import styles from './modalFileUploadDropBox.module.css';

interface ModalFileUploadDropBoxProps {
  modalName: string;
  modalTitle: string;
  onUpload: ({ voiceId, files }: { voiceId: string; files: FileList }) => void;
  isLoading?: boolean;
  uploadProgress?: number;
  acceptFileExtensions?: string;
  hasError?: boolean;
  hasRestriction?: boolean;
  closeOnClickOutside?: boolean;
  onClose?: () => void;
}

export const ModalFileUploadDropBox = ({
  modalName,
  modalTitle,
  onUpload,
  acceptFileExtensions = ALLOWED_SONG_EXTS.join(','),
  isLoading = false,
  uploadProgress,
  hasRestriction = false,
  hasError = false,
  closeOnClickOutside,
  onClose
}: ModalFileUploadDropBoxProps) => {
  const { modalOption } = useModalStore();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadBoxFile = () => {
    if (isLoading) {
      return;
    }

    fileInputRef.current?.click();
  };

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (isLoading) {
      return;
    }

    const input = event.target;
    const files = input.files;

    if (!files) {
      return;
    }
    onUpload({ voiceId: modalOption.voiceId as string, files });
    input.value = '';
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Modal
        name={modalName}
        title={modalTitle}
        size="550px"
        isCentered
        closeOnClickOutside={closeOnClickOutside}
        onClose={handleClose}
      >
        {(hasError || hasRestriction) && (
          <div className="bg-hookyred-500 text-white rounded-xl p-4 mb-6 flex items-center">
            <Icon icon="Info" className="w-12 h-12 p-3" />
            <div className="flex-1 flex flex-col">
              <Typo.p className="text-sm text-white">
                {hasError
                  ? 'An error has occurred while uploading your files.'
                  : 'Sorry, this does not match our upload criteria.'}
              </Typo.p>
              {hasRestriction && (
                <Typo.p className="text-xs">
                  Please upload a WAV, AIFF, or MP3 file of 100mb or less. The overall upload size
                  must not exceed 2GB. Or check if this file is uploaded already
                </Typo.p>
              )}
            </div>
          </div>
        )}
        <div className="cursor-pointer border border-hookybase-300 border-dashed rounded-xl flex items-center justify-center flex-col gap-6 h-48 mb-6 relative">
          <Typo.p className="text-hookybase-900 dark:text-white">
            Drag and drop your files here
          </Typo.p>

          <ButtonLib
            onClick={handleUploadBoxFile}
            loading={isLoading}
            className={styles.uploadBtn}
            variant="outline"
            background="secondary"
            size="xs"
          >
            {!uploadProgress || uploadProgress === 0 ? (
              <>OR CHOOSE FILE</>
            ) : (
              <>
                <Icon icon="Spinner" className="animate-spin w-6 h-6 mr-2" /> {uploadProgress}%
              </>
            )}
          </ButtonLib>

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleUploadFile}
            multiple={true}
            accept={acceptFileExtensions}
            className={styles.inputField}
            title=""
            disabled={isLoading}
          />
        </div>
        <Typo.p className="text-sm text-hookybase-200 mb-2">
          Keep your input audio clean, dry and free from background noise or instrumentals.
        </Typo.p>
        <Typo.p className="text-xs mb-1">
          <span className="text-hookybase-300 mr-2">File types:</span>
          <span className="text-hookybase-200">MP3, WAV or AIFF only</span>
        </Typo.p>
        <Typo.p className="text-xs text-hookybase-300">
          <span className="text-hookybase-300 mr-2">Upload limit:</span>
          <span className="text-hookybase-200">100mb per file / 2GB overall</span>
        </Typo.p>
      </Modal>
    </>
  );
};
