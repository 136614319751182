import React from 'react';

const MusicIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3333 28C11.8667 28 10.6111 27.4778 9.56667 26.4333C8.52222 25.3889 8 24.1333 8 22.6667C8 21.2 8.52222 19.9444 9.56667 18.9C10.6111 17.8556 11.8667 17.3333 13.3333 17.3333C13.8444 17.3333 14.3167 17.3944 14.75 17.5167C15.1833 17.6389 15.6 17.8222 16 18.0667V4H24V9.33333H18.6667V22.6667C18.6667 24.1333 18.1444 25.3889 17.1 26.4333C16.0556 27.4778 14.8 28 13.3333 28Z"
        fill="currentColor"
      />
    </svg>
  );
};

MusicIcon.displayName = 'MusicIcon';

export default MusicIcon;
