export enum ROUTES {
  ACCOUNT = '/account',
  ACCOUNT_HOME = '/account/my-account',
  ACCOUNT_PROFILE = '/account/profile',
  ACCOUNT_SUBSCRIPTION = '/account/subscription',
  ACCOUNT_PAYMENT_DETAILS = '/account/payment-details',
  ACCOUNT_SECURITY = '/account/security',
  ACCOUNT_ARTIST_APPROVAL = '/account/artist-approval',
  ACCOUNT_CHANGE_PASSWORD = '/account/security/change-password',
  ACCOUNT_DELETE = '/account/delete',
  APPROVALS = '/approvals',
  ARTISTS = '/artists',
  CREATE = '/create',
  DASHBOARD = '/',
  DASHBOARD_HOME = '/dashboard',
  PROJECTS = '/projects',
  PROJECTS_COLLABORATIONS = '/projects/collaborations',
  PROJECTS_LIBRARY = '/projects/library',
  EMAIL = '/email-hook',
  COLLABORATIONS = '/collaborations',
  RELEASES = '/releases',
  SITE_TERMS = '/terms',
  PRIVACY_POLICY = '/terms/privacy-policy',
  TERMS_OF_USE = '/terms/terms-of-use',
  SUBSCRIPTIONS_TERMS = '/terms/subscription-terms',
  COOKIE_POLICY = '/terms/cookie-policy',
  NOT_AUTHORIZED = '/not-authorized'
}
