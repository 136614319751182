import React from 'react';

const InfoIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6641 22.6667H17.3307V14.6667H14.6641V22.6667ZM15.9974 12.0001C16.3752 12.0001 16.6918 11.8723 16.9474 11.6167C17.203 11.3612 17.3307 11.0445 17.3307 10.6667C17.3307 10.289 17.203 9.9723 16.9474 9.71675C16.6918 9.46119 16.3752 9.33341 15.9974 9.33341C15.6196 9.33341 15.303 9.46119 15.0474 9.71675C14.7918 9.9723 14.6641 10.289 14.6641 10.6667C14.6641 11.0445 14.7918 11.3612 15.0474 11.6167C15.303 11.8723 15.6196 12.0001 15.9974 12.0001ZM15.9974 29.3334C14.153 29.3334 12.4196 28.9834 10.7974 28.2834C9.17517 27.5834 7.76406 26.6334 6.56406 25.4334C5.36406 24.2334 4.41406 22.8223 3.71406 21.2001C3.01406 19.5779 2.66406 17.8445 2.66406 16.0001C2.66406 14.1556 3.01406 12.4223 3.71406 10.8001C4.41406 9.17786 5.36406 7.76675 6.56406 6.56675C7.76406 5.36675 9.17517 4.41675 10.7974 3.71675C12.4196 3.01675 14.153 2.66675 15.9974 2.66675C17.8418 2.66675 19.5752 3.01675 21.1974 3.71675C22.8196 4.41675 24.2307 5.36675 25.4307 6.56675C26.6307 7.76675 27.5807 9.17786 28.2807 10.8001C28.9807 12.4223 29.3307 14.1556 29.3307 16.0001C29.3307 17.8445 28.9807 19.5779 28.2807 21.2001C27.5807 22.8223 26.6307 24.2334 25.4307 25.4334C24.2307 26.6334 22.8196 27.5834 21.1974 28.2834C19.5752 28.9834 17.8418 29.3334 15.9974 29.3334Z"
        fill="currentColor"
      />
    </svg>
  );
};

InfoIcon.displayName = 'InfoIcon';

export default InfoIcon;
