import React from 'react';

const FilterIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3333 24V21.3333H18.6667V24H13.3333ZM8 17.3333V14.6667H24V17.3333H8ZM4 10.6667V8H28V10.6667H4Z"
        fill="currentColor"
      />
    </svg>
  );
};

FilterIcon.displayName = 'FilterIcon';

export default FilterIcon;
